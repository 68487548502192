import { fetchData, postData } from "./api";
let localToken = localStorage.getItem("token");
let sessionToken = sessionStorage.getItem("rakExUserSession");
let token = sessionToken ? sessionToken : localToken;
let siteLanguage = Boolean(JSON.parse(localStorage.getItem("isRTL")))
  ? "ar"
  : "en";
export const getData = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, { language: siteLanguage });

export const getAll = async (
  sort,
  subSort,
  subType,
  tender,
  last3Years,
  acceptSponsors,
  forYearly,
  categoryId,
  eventId,
  requestId,
  hasAllocation,
  language
) =>
  await fetchData(
    `/api/${sort}`,
    {
      type: subSort ? subSort : null,
      subType: subType ? subType : null,
      categoryId: categoryId ? categoryId : null,
      forTender: tender ? tender : null,
      last3Years: last3Years ? last3Years : null,
      acceptSponsors: acceptSponsors ? acceptSponsors : null,
      forYearly: forYearly ? forYearly : false,
      eventId: eventId ? eventId : null,
      requestId: requestId ? requestId : null,
      hasAllocation: hasAllocation ? hasAllocation : false,
    },
    null,
    {
      language: language ? language : siteLanguage,
    }
  );
export const getCompact = async (data, sort) =>
  await fetchData(`/api/${sort}`, data, null);

export const getAllItems = async (sort, subSort, subType, sponsorTypeId) =>
  await fetchData(`/api/${sort}`, {
    type: subSort ? subSort : null,
    subType: subType ? subType : null,
    sponsorTypeId: sponsorTypeId ? sponsorTypeId : null,
  });

export const getById = async (sort, id) =>
  await fetchData(`/api/${sort}/${id}`, null);

export const getByAlias = async (sort, alias, language) =>
  await fetchData(`/api/${sort}/${alias}`, null, {
    language: language ? language : "en",
  });

export const createNew = async (sort, data) => {
  return await postData(`/api/${sort}`, data, {
    timeOffset: new Date().getTimezoneOffset(),
  });
};

export const createNewReservation = async (data) => {
  return await postData(`/api/service/request/booking`, data, {
    timeOffset: new Date().getTimezoneOffset(),
  });
};

export const createNewSpaceReservation = async (data) => {
  return await postData(`/api/service/request/allocation`, data, {
    timeOffset: new Date().getTimezoneOffset(),
  });
};

export const calculate = async (data) => {
  return await postData(`/api/service/request/booking/calculate`, data);
};

export const update = async (sort, data, id) =>
  await postData(`/api/${sort}/${id}/update`, data);

export const getReservations = async (sort, language) =>
  await fetchData(`/api/${sort}`, language);

export const getSupporting = async (sort, subSort) =>
  await fetchData(`/api/${sort}`, {
    type: subSort ? subSort : null,
  });

export const cancelItem = async (sort, id) =>
  await postData(`/api/${sort}/${id}/cancel`, null);

export const getAllAb = async (sort, subSort, entity, categoryId) =>
  await fetchData(`/api/${sort}`, {
    type: subSort ? subSort : null,
    subType: entity ? entity : null,
    categoryId: categoryId ? categoryId : null,
  });

export const getLastUpdate = async (sort) => await fetchData(`/api/${sort}`);

export const getNotifications = async (sort, limit, offset) =>
  await fetchData(`/api/${sort}`, {
    limit: limit ? limit : 100,
    offset: offset ? offset : 0,
  });

export const payment = async (data) => {
  return await postData(`/api/payment`, data, {
    token,
  });
};
