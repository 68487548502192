export const payload = (values, type, files) => {
  console.log(values, "valuesPayloaddddddd");
  let responsorTypesValue = [];
  if (values?.email) responsorTypesValue.push("email");
  if (values?.phone) responsorTypesValue.push("phone");

  switch (type) {
    case "service/request/besponsor":
      return {
        eventProposalId: values.eventProposalId,
        isYerly: values.isYearly ? values.isYearly : false,
        eventId: values.eventId,
        message: values.message,
        responseTypes: values.responseTypes,
        sponsorTypeId: values.sponsorTypeId,
        startingPrice: values.finalCost
          ? Number.parseFloat(values.finalCost)?.toFixed(2)
          : 0,
      };
    case "notification/mark/mine":
      return {
        ids: values,
      };
    case "replay/user":
      return {
        from: values?.from ? values?.from : "",
        to: values?.to ? values?.to : "",
        subject: values?.subject ? values?.subject : "",
        message: values?.message ? values?.message : "",
        fileIds: files,
        requestId: values?.requestId ? values?.requestId : null,
      };
    case "contact/ideas":
      return {
        fileId: values?.attachId,
        title: values?.title,
        name: values?.username,
        email: values?.email,
        message: values?.description,
        phoneNumber: values?.phoneNumber,
      };
    case "service/request/permit":
      return {
        exhibitionName: values.exhibitionName,
        eventCategoryId: values.eventCategory,
        permitType: values.permitType,
        exhibitionLocation: values.exhibitionLocation,
        responseTypes: values.responseTypes,
        message: values.message,
        eventDurationFrom: values.eventDurationFrom,
        eventDurationTo: values.eventDurationTo,
        exhibitionStudyIds: values.exhibitionStudyIds,
        exhibitionFloorIds: values.exhibitionFloorIds,
        exhibitorsNamesIds: values.exhibitorsNamesIds,
        economicPermitIds: values.economicPermitIds,
        exactLocationIds: values.exactLocationIds,
        noObjectionIds: values.noObjectionIds,
        startingPrice: values.finalCost
          ? Number.parseFloat(values.finalCost)?.toFixed(2)
          : 0,
      };
    case "service/request/booking":
      let payload = {};
      payload = {
        startDate: values.startDate,
        endDate: values.endDate,
        checkInDate: values.checkInDate,
        checkOutDate: values.checkOutDate,
        roomIds: values.roomId,
        facility: values.facilites,
        responseTypes: values.responseTypes,
        message: values.message,
        eventName: values.exhibitionName,
        eventCategoryId: values.exhibitionCategory,
        exhibitionStudyIds: values.exhibitionStudyIds,
        exhibitionFloorIds: values.exhibitionFloorIds,
        exhibitorsNamesIds: values.exhibitorsNamesIds,
        economicPermitIds: values.economicPermitIds,
        anySpeaker: values.anySpeaker,
        startingPrice: values.startingPrice
          ? Number.parseFloat(values.startingPrice)?.toFixed(2)
          : 0,
        // };
      };
      return payload;
    case "service/request/tender":
      return {
        message: values.message,
        responseTypes: values.responseTypes,
        exhibitionStudyIds: values.exhibitionStudyIds,
        exhibitionFloorIds: values.exhibitionFloorIds,
        exhibitorsNamesIds: values.exhibitorsNamesIds,
        economicPermitIds: values.economicPermitIds,
        tenderId: values.tenderId,
      };

    case "comment":
      return {
        name: values.name,
        email: values.email,
        data: values.message,
        attachmentId: values.attachId,
        postId: values.postId,
        postLanguage: values.postLanguage,
        phoneNumber: values.number,
        userTitle: values.title,
      };
    default:
      break;
  }
  return { payload };
};
export default payload;
