import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& div.MuiDialog-container": {
      "& div.MuiPaper-root": {
        overflow: "hidden",
        backgroundImage: "url(/assets/exit1.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        transform:
          theme.direction === "rtl" ? "rotate(+180deg)" : "rotate(0deg)",

        "& div.MuiGrid-container": {
          transform:
            theme.direction === "rtl" ? "rotate(270deg)" : "rotate(0deg)",
        },

        "& div.close": {
          float: "right",
          width: "19px",
          height: "28px",
          color: theme.palette.secondary.main,
          opacity: 1,
          cursor: "pointer",
          padding: "2px 30px 5px 0",
          fontSize: theme.globals.fontSize.m,
        },

        "& div.content": {
          display: "flex",
          justifyContent: "space-between",
          flexDirection: theme.direction === "rtl" ? "row-reverse" : "row",
          marginTop: 20,

          "& div.MuiDialogTitle-root": {
            paddingTop: 30,
            paddingLeft: 4,
            width: "42%",
            height: "42%",
            "& h2": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: theme.globals.fontSize.xl + 12,
              left: "20%",
              lineHeight: "96%",
              color: theme.palette.secondary.main,
              position: "relative",
              textAlign: "start",
              direction:
                theme.direction === "rtl" ? "rtl!important" : "ltr!important",
              [theme.breakpoints.down(500)]: {
                fontSize: theme.globals.fontSize.xl - 8,
              },
              "&:before": {
                position: "absolute",
                top: "-15px",
                content: "''",
                width: 56,
                height: 4,
                backgroundColor: theme.palette.secondary.main,
              },
            },
          },

          "& div.MuiDialogContent-root": {
            "& p": {
              fontFamily:
                theme.direction === "rtl"
                  ? `${theme.globals.fontFamily.ar}!important`
                  : `${theme.globals.fontFamily.en}!important`,
              fontStyle: "normal",
              fontWeight: "normal",
              fontSize: theme.globals.fontSize.s + 2,
              lineHeight: "26px",
              color: "#29495E",
              textAlign: "start",
              direction:
                theme.direction === "rtl" ? "rtl!important" : "ltr!important",
              [theme.breakpoints.down(500)]: {
                fontSize: theme.globals.fontSize.s - 2,
              },
              [theme.breakpoints.down(375)]: {
                fontSize: theme.globals.fontSize.s - 4,
              },
            },

            "& div.btnContainer": {
              padding: "10px 0 18px 0",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              "& button": {
                // width: theme.direction === "rtl" ? 85 : 112,
                height: 30,
                backgroundColor: theme.palette.secondary.main,
                borderRadius: "2px",
                // [theme.breakpoints.down(500)]: {
                //   width: theme.direction === "rtl" ? 73 : 100,
                // },
                "& span.MuiButton-label": {
                  fontFamily:
                    theme.direction === "rtl"
                      ? `${theme.globals.fontFamily.ar}!important`
                      : `${theme.globals.fontFamily.en}!important`,
                  fontStyle: "normal",
                  fontSize: theme.globals.fontSize.s - 2,
                  lineHeight: "19px",
                  color: theme.globals.colors.white,
                  textTransform: "capitalize",
                },
              },

              "& button:last-of-type": {
                clipPath: "polygon(15% 0, 100% 0, 100% 100%, 0 100%)",
              },

              "& button:first-of-type": {
                clipPath: "polygon(0 0, 100% 0, 85% 100%, 0 100%)",
              },
            },
          },
        },
      },
    },
  },
  contentContainer: {
    padding: "8px 16px",
  },
}));

export default useStyles;
