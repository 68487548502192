import actions from "../../actions";
import { combineReducers } from "redux";

const { GET_MENU, MENU_RETURNED } = actions;

export const getMenu = (state = false, action) => {
  switch (action.type) {
    case GET_MENU:
      return true;
    case MENU_RETURNED:
      return false;
    default:
      return state;
  }
};
export const menuReturned = (state = false, action) => {
  switch (action.type) {
    case MENU_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getAllPaths = (state = false, action) => {
  switch (action.type) {
    case MENU_RETURNED:
      if (!action.data.length) return;
      if (action.data.length) {
        const data = action.data.find((item) => item.title.en === "header");

        const menu = data?.childrens;
        let allPaths = [];

        menu.map((item) => {
          allPaths.push(item);

          if (item.childrens.length) {
            item.childrens.map((child) => {
              allPaths.push(child);

              if (child.childrens.length) {
                child.childrens.map((grandChild) => allPaths.push(grandChild));
              }
            });
          }
        });

        return allPaths;
      }

    default:
      return state;
  }
};

export default combineReducers({
  getMenu,
  menuReturned,
  getAllPaths,
});
