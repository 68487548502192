import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { fetchPageInfo } from "../../network/page";
import actions from "../../actions";

const { GET_PAGE_DETAILS, pageDetailsReturned } = actions;

function* performGetPageInfo(action) {
  try {
    const data = action.data;
    const result = yield call(fetchPageInfo, data);

    if (result) yield put(pageDetailsReturned({ data: result.res }));
    else yield put(pageDetailsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetPageInfo() {
  yield takeLatest(GET_PAGE_DETAILS, performGetPageInfo);
}
