import { put, takeLatest, call } from "redux-saga/effects";
import actions from "../../actions";

const { THANKS_ACTION, thanksActionReturned } = actions;

function* performGetThanks(action) {
  try {
    const { thanks } = action;
    yield put(thanksActionReturned({ data: thanks }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchThanks() {
  yield takeLatest(THANKS_ACTION, performGetThanks);
}
