import { call, put, takeLatest } from "redux-saga/effects";
import actions from "../../actions";
import { fetchEServicesDetails } from "../../network/APIServices";

const { GET_ESERVICES_DETAILS, getEservicesDetailsReturned } = actions;

function* performEservicesDetails(action) {
  try {
    const data = action.data;
    const result = yield call(fetchEServicesDetails, data);

    if (result && result.networkSuccess)
      yield put(getEservicesDetailsReturned({ data: result }));
    else yield put(getEservicesDetailsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
    yield put(getEservicesDetailsReturned({ data: [] }));
  }
}

export function* watchEservicesDetails() {
  yield takeLatest(GET_ESERVICES_DETAILS, performEservicesDetails);
}
