import actions from "../../actions";
import { combineReducers } from "redux";
const {
  GET_ALL,
  ALL_RETURNED,
  GET_DATA_ALL,
  DATA_ALL_RETURNED,
  GET_ALL_ITEMS,
  ALL_ITEMS_RETURNED,
  ALL_CLEAR,
  GET_BY_ID,
  BY_ID_RETURNED,
  GET_BY_ALIAS,
  CLEAR,
  BY_ALIAS_RETURNED,
  CREATE_NEW,
  CREATED,
  GET_RESERVATIONS,
  ALL_RESERVATIONS_RETURNED,
  GET_SUPPORTING,
  ALL_SUPPORTING_RETURNED,
  CANCEL_ITEM,
  CANCELED,
  NEW_RESERVATION,
  ALL_AB_RETURNED,
  GET_ALL_AB,
  CALCULATE,
  CALCULATED,
  CREATE_NEW_RESERVATION,
  RESERVATION_CREATED,
  GET_UPDATE_DATE,
  GET_LAST_UPDATE,
  LAST_UPDATE_RETURNED,
  GET_NOTIFICATIONS,
  ALL_NOTIFICATIONS_RETURNED,
  PAYMENT,
  PAYMENT_COMPLETED,
  CREATE_NEW_SPACE_RESERVATION,
  SPACE_RESERVATION_CREATED,
  GET_COMPACT,
  COMPACT_RETURNED,
} = actions;

export const getCompact = (state = false, action) => {
  switch (action.type) {
    case GET_COMPACT:
      return action?.data?.spinner === "none" ? false : true;
    case COMPACT_RETURNED:
      return false;
    default:
      return state;
  }
};
export const compactReturned = (state = [], action) => {
  switch (action.type) {
    case COMPACT_RETURNED:
      return {
        ...state,
        [action?.subSort ? action?.subSort : action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getUpdateDate = (state = false, action) => {
  switch (action.type) {
    case GET_UPDATE_DATE:
      return action.data;
    default:
      return state;
  }
};

export const getAll = (state = false, action) => {
  switch (action.type) {
    case GET_ALL:
      return true;
    case ALL_RETURNED:
      return false;
    default:
      return state;
  }
};

export const allReturn = (state = [], action) => {
  switch (action.type) {
    case ALL_RETURNED:
      if (action.data.success) {
        if (action.data.posts?.length) {
          const sort = action.data.posts[0].type;
          return {
            ...state,
            [sort]: action.data,
          };
        } else return state;
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const allReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_RETURNED:
      return action.data.returnedTypeName
        ? {
            ...state,
            [action.data.returnedTypeName]:
              action.data[action.data.returnedTypeName],
          }
        : {
            ...state,
            ["returnedData"]: action.data,
          };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const getAllItems = (state = false, action) => {
  switch (action.type) {
    case GET_ALL_ITEMS:
      return true;
    case ALL_ITEMS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allItemsReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_ITEMS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};
export const getDataAll = (state = false, action) => {
  switch (action.type) {
    case GET_DATA_ALL:
      return true;
    case DATA_ALL_RETURNED:
      return false;
    default:
      return state;
  }
};
export const dataAllReturned = (state = [], action) => {
  switch (action.type) {
    case DATA_ALL_RETURNED:
      return {
        ...state,

        [action.data.returnedTypeName
          ? action.data.returnedTypeName
          : action?.dataObject]: action.data[
          action.data.returnedTypeName
            ? action.data.returnedTypeName
            : action.data.result
        ]
          ? action.data[action.data.returnedTypeName]
          : action?.data,
        count: action.data.count ? action.data.count : null,
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};
export const getById = (state = false, action) => {
  switch (action.type) {
    case GET_BY_ID:
      return true;
    case BY_ID_RETURNED:
      return false;
    default:
      return state;
  }
};

export const byIdReturned = (state = false, action) => {
  switch (action.type) {
    case BY_ID_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getByAlias = (state = false, action) => {
  switch (action.type) {
    case GET_BY_ALIAS:
      return true;
    case BY_ALIAS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const byAliasReturned = (state = false, action) => {
  switch (action.type) {
    case BY_ALIAS_RETURNED:
      return action.data;
    default:
      return state;
  }
};
export const createNew = (state = false, action) => {
  switch (action.type) {
    case CREATE_NEW:
      return true;
    case CREATED:
      return false;
    default:
      return state;
  }
};
export const created = (state = false, action) => {
  switch (action.type) {
    case CREATED:
      return action.response;
    case CLEAR:
      return false;
    default:
      return state;
  }
};

export const createNewReservation = (state = false, action) => {
  switch (action.type) {
    case CREATE_NEW_RESERVATION:
      return true;
    case RESERVATION_CREATED:
      return false;
    default:
      return state;
  }
};
export const reservationCreated = (state = false, action) => {
  switch (action.type) {
    case RESERVATION_CREATED:
      return action.response;
    default:
      return state;
  }
};

export const createNewSpaceReservation = (state = false, action) => {
  switch (action.type) {
    case CREATE_NEW_SPACE_RESERVATION:
      return true;
    case SPACE_RESERVATION_CREATED:
      return false;
    default:
      return state;
  }
};
export const spaceReservationCreated = (state = false, action) => {
  switch (action.type) {
    case SPACE_RESERVATION_CREATED:
      return action.response;
    default:
      return state;
  }
};

export const calculate = (state = false, action) => {
  switch (action.type) {
    case CALCULATE:
      return true;
    case CALCULATED:
      return false;
    default:
      return state;
  }
};
export const calculated = (state = false, action) => {
  switch (action.type) {
    case CALCULATED:
      return action.response;
    default:
      return state;
  }
};

export const getLastUpdate = (state = false, action) => {
  switch (action.type) {
    case GET_LAST_UPDATE:
      return true;
    case LAST_UPDATE_RETURNED:
      return false;
    default:
      return state;
  }
};
export const lastUpdateReturned = (state = false, action) => {
  switch (action.type) {
    case LAST_UPDATE_RETURNED:
      return action.response;
    default:
      return state;
  }
};

export const newReservation = (state = false, action) => {
  switch (action.type) {
    case NEW_RESERVATION:
      return action.payload;
    default:
      return state;
  }
};

export const getReservations = (state = false, action) => {
  switch (action.type) {
    case GET_RESERVATIONS:
      return true;
    case ALL_RESERVATIONS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allReservationsReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_RESERVATIONS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};

export const cancelItem = (state = false, action) => {
  switch (action.type) {
    case CANCEL_ITEM:
      return true;
    case CANCELED:
      return false;
    default:
      return state;
  }
};
export const canceled = (state = false, action) => {
  switch (action.type) {
    case CANCELED:
      return action.response;

    default:
      return state;
  }
};
export const getSupporting = (state = false, action) => {
  switch (action.type) {
    case GET_SUPPORTING:
      return true;
    case ALL_SUPPORTING_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allSupportingReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_SUPPORTING_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    case ALL_CLEAR:
      return {
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };
    default:
      return state;
  }
};
export const getAllAb = (state = false, action) => {
  switch (action.type) {
    case GET_ALL_AB:
      return true;
    case ALL_AB_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allAbReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_AB_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
      };

    default:
      return state;
  }
};

export const getNotifications = (state = false, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return true;
    case ALL_NOTIFICATIONS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allNotificationsReturned = (state = [], action) => {
  switch (action.type) {
    case ALL_NOTIFICATIONS_RETURNED:
      return {
        ...state,
        [action.data.returnedTypeName]:
          action.data[action.data.returnedTypeName],
        count: action.data.count,
      };
    default:
      return state;
  }
};

export const payment = (state = false, action) => {
  switch (action.type) {
    case PAYMENT:
      return true;
    case PAYMENT_COMPLETED:
      return false;
    default:
      return state;
  }
};
export const paymentCompleted = (state = false, action) => {
  switch (action.type) {
    case PAYMENT_COMPLETED:
      return action.response;
    default:
      return state;
  }
};

export default combineReducers({
  getNotifications,
  allNotificationsReturned,
  getAll,
  allReturned,
  allReturn,
  getDataAll,
  dataAllReturned,
  getAllItems,
  allItemsReturned,
  getById,
  byIdReturned,
  getByAlias,
  byAliasReturned,
  createNew,
  created,
  newReservation,
  getReservations,
  allReservationsReturned,
  cancelItem,
  canceled,
  getSupporting,
  allSupportingReturned,
  getAllAb,
  allAbReturned,
  calculate,
  calculated,
  createNewReservation,
  reservationCreated,
  getUpdateDate,
  getLastUpdate,
  lastUpdateReturned,
  payment,
  paymentCompleted,
  createNewSpaceReservation,
  spaceReservationCreated,
  getCompact,
  compactReturned,
});
