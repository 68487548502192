import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import theme_reducer from "./theme";
import posts_reducers from "./groups/post";
import contactus from "./groups/contactus";
import users from "./groups/users";
import careers from "./groups/careers";
import files from "./groups/file";
import services from "./groups/services";
import category from "./groups/category";
import links from "./groups/link";
import surveys from "./groups/survey";
import comment from "./groups/comment";
import request from "./groups/request";
import rate from "./groups/rate";
import search from "./groups/search";
import crudReducers from "./groups/crudReducer";
import menu from "./groups/menu";
import pagePicture from "./groups/pagePicture";
import page from "./groups/page";
import sitemap from "./groups/sitemap";
import language from "./groups/language";
import map from "./groups/map";
import filter from "./groups/filter";
import loading from "./groups/loading";
import thanks from "./groups/thanks";
import APIServices from "./groups/APIServices";
const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    theme_reducer,
    posts_reducers,
    contactus,
    users,
    careers,
    files,
    services,
    category,
    links,
    surveys,
    comment,
    request,
    rate,
    search,
    crudReducers,
    menu,
    pagePicture,
    page,
    sitemap,
    language,
    map,
    filter,
    loading,
    thanks,
    APIServices,
  });

export default rootReducer;
