import { put, takeLatest, call } from "redux-saga/effects";

import {
  logIn,
  signUp,
  forgotPassword,
  resetPassword,
  userConfirm,
  activateAccount,
  updateUser,
} from "../../network/users";
import actions from "../../actions";
import { push } from "connected-react-router";
import { store } from "../../store";
import { createFile, uploadFile } from "../../network/file";

const {
  LOG_IN_ACTION,
  logInComplete,
  SIGN_UP_ACTION,
  signUpComplete,
  FORGOT_PASSWORD_ACTION,
  forgotPasswordEmailReturned,
  RESET_PASSWORD_ACTION,
  resetPasswordReturned,
  CONFIRM_USER,
  confirmUserReturned,
  LOG_OUT,
  fileUploaded,
  ACTIVATE_ACCOUNT,
  activateAccountReturned,
  UPDATE_USER_ACTION,
  userUpdatedComplete,
} = actions;

function* performSignIn(action) {
  try {
    let data = action.payload;
    const result = yield call(logIn, data);
    localStorage.setItem("remember", data.remember);
    if (result) {
      yield put(logInComplete({ data: result.data }));
      // sessionStorage.setItem("rakExUserSession", result.data.token);
      // sessionStorage.setItem(
      //   "profileSession",
      //   JSON.stringify(result.data.user)
      // );

      // if (data.remember) {
      //   localStorage.setItem("token", result.data.token);
      // }
    } else yield put(logInComplete({ data: [] }));
  } catch {
    yield put(logInComplete({ data: [] }));
    return;
  }
}

export function* watchLogIn() {
  yield takeLatest(LOG_IN_ACTION, performSignIn);
}

function* performSignUp(action) {
  let filesIds = [];
  try {
    let data = action.payload;
    let idCopy = data.idCopy;
    let validTradeLicense = data.validTradeLicense;
    let companyProfile = data.companyProfile;

    if (idCopy && idCopy.length > 0) {
      var formData = new FormData();
      idCopy.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "ID Copy", ar: "صورة عن الهوية الشخصية" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
      data = { ...data, idCopyId: filesIds[0] };
    }

    if (validTradeLicense && validTradeLicense.length > 0) {
      var formData = new FormData();
      validTradeLicense.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "Valid Trade License", ar: "سجل تجاري صالح" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);

      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
      data = { ...data, validTradeLicenseId: filesIds[1] };
    }

    if (companyProfile && companyProfile.length > 0) {
      var formData = new FormData();
      companyProfile.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "Company Profile", ar: "ملف تعريف الشركة" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
      data = { ...data, companyProfileId: filesIds[2] };
    }

    const result = yield call(signUp, data);
    if (result) yield put(signUpComplete({ data: result.data }));
    else yield put(signUpComplete({ data: [] }));
  } catch {
    yield put(signUpComplete({ data: [] }));
    return;
  }
}

export function* watchSignUp() {
  yield takeLatest(SIGN_UP_ACTION, performSignUp);
}

function* performForgotPassword(action) {
  try {
    let data = action.payload;
    const result = yield call(forgotPassword, data);
    if (result) yield put(forgotPasswordEmailReturned({ data: result.data }));
    else yield put(forgotPasswordEmailReturned({ data: result.data }));
  } catch {
    yield put(forgotPasswordEmailReturned({ data: [] }));
    return;
  }
}

export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD_ACTION, performForgotPassword);
}

function* performActivateAccount(action) {
  try {
    let data = action.payload;
    const result = yield call(activateAccount, data);
    if (result) yield put(activateAccountReturned({ data: result.data }));
    else yield put(activateAccountReturned({ data: result.data }));
  } catch {
    yield put(activateAccountReturned({ data: [] }));
    return;
  }
}

export function* watchActivateAccount() {
  yield takeLatest(ACTIVATE_ACCOUNT, performActivateAccount);
}

function* performResetPassword(action) {
  try {
    let data = action.payload;
    const result = yield call(resetPassword, data);
    if (result) yield put(resetPasswordReturned({ data: result.data }));
    else yield put(resetPasswordReturned({ data: {} }));
  } catch {
    yield put(resetPasswordReturned({ data: [] }));
    return;
  }
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD_ACTION, performResetPassword);
}

function* performConfirmUser(action) {
  try {
    let data = action.data;
    const result = yield call(userConfirm, data);
    if (result) yield put(confirmUserReturned({ data: result.data }));
    else yield put(confirmUserReturned({ data: [] }));
  } catch {
    yield put(confirmUserReturned({ data: [] }));
    return;
  }
}

export function* watchConfirmUser() {
  yield takeLatest(CONFIRM_USER, performConfirmUser);
}

function* performUpdateUser(action) {
  try {
    let data = action.data;
    let filesIds = [];
    let id = data?.id;
    let idCopy = data.idCopy;
    let validTradeLicense = data.validTradeLicense;
    let companyProfile = data.companyProfile;
    let idCopyId = null;
    let validTradeLicenseId = null;
    let companyProfileId = null;

    if (idCopy && idCopy.length > 0) {
      var formData = new FormData();
      idCopy.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "ID Copy", ar: "صورة عن الهوية الشخصية" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      idCopyId = allFilesPayload.data.files[0].id;
      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
     // data = { ...data, idCopyId: filesIds[0] };
    }

    if (validTradeLicense && validTradeLicense.length > 0) {
      var formData = new FormData();
      validTradeLicense.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "Valid Trade License", ar: "سجل تجاري صالح" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      validTradeLicenseId = allFilesPayload.data.files[0].id;
      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
      //data = { ...data, validTradeLicenseId: filesIds[1] };
    }

    if (companyProfile && companyProfile.length > 0) {
      var formData = new FormData();
      companyProfile.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: { en: "Company Profile", ar: "ملف تعريف الشركة" },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      companyProfileId = allFilesPayload.data.files[0].id;
      allFilesPayload.data.files.map((files) => filesIds.push(files.id));
     // data = { ...data, companyProfileId: filesIds[2] };
    }
    data = { ...data, validTradeLicenseId,idCopyId,companyProfileId };
    const result = yield call(updateUser, data, id);
    if (result) yield put(userUpdatedComplete({ data: result.data }));
    else yield put(userUpdatedComplete({ data: [] }));
  } catch {
    yield put(userUpdatedComplete({ data: [] }));
    return;
  }
}

export function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER_ACTION, performUpdateUser);
}

function* performLogOut() {
  localStorage.removeItem("rakExUser");
  localStorage.removeItem("token");
  sessionStorage.removeItem("rakExUserSession");
  sessionStorage.removeItem("profileSession");
  localStorage.removeItem("profile");
  localStorage.removeItem("directURL");
  yield put(logInComplete({ data: null }));
  store.dispatch(push("/home"));
}

export function* watchLogOut() {
  yield takeLatest(LOG_OUT, performLogOut);
}
