import actions from "../../actions";
import { combineReducers } from "redux";

const { GET_ESERVICES_DETAILS, GET_ESERVICES_DETAILS_RETURNED } = actions;

export const getEServicesDetails = (state = false, action) => {
  switch (action.type) {
    case GET_ESERVICES_DETAILS:
      return true;
    case GET_ESERVICES_DETAILS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const eServicesDetailsReturned = (state = {}, action) => {
  switch (action.type) {
    case GET_ESERVICES_DETAILS_RETURNED:
      return action.data;
    default:
      return state;
  }
};
export default combineReducers({
  getEServicesDetails,
  eServicesDetailsReturned,
});
