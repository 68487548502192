import store from "../store";

const fetchData = async (endpoint, params, customHeaders) => {
  let token = sessionStorage.getItem("rakExUserSession")
    ? sessionStorage.getItem("rakExUserSession")
    : localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;

  const {
    language: { currentLanguage },
  } = store.getState();

  let headers2 = {
    token,
    language: currentLanguage,
  };

  const headers = { ...headers2, ...customHeaders };

  const searchParams = Object.entries(params || {})
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

  const url = `${endpoint}?${searchParams}`;
  return fetch(url, { method: "GET", headers })
    .then((res) => {
      return res.json();
    })
    .then((res) => {
      if (res.hasOwnProperty("statusCode")) {
        return { networkSuccess: false, ...res };
      } else {
        return { networkSuccess: true, res };
      }
    })
    .catch((e) => {
      return { networkSuccess: false };
    });
};

const postData = async (endpoint, body, customHeaders) => {
  let token = sessionStorage.getItem("rakExUserSession")
    ? sessionStorage.getItem("rakExUserSession")
    : localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  let language = "en";

  let headers2 = {
    token,
    language,
  };
  let headers = { accept: "application/json", ...customHeaders, ...headers2 };
  const url = `${endpoint}`;
  if (body) {
    headers["Content-Type"] = "application/json";
  }

  return fetch(url, {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.hasOwnProperty("statusCode")) {
        return { networkSuccess: false, ...res };
      } else {
        return { networkSuccess: true, data: res };
      }
    })
    .catch((e) => ({ networkSuccess: false }));
};

const rakURL = "https://chamber-test.com/ords/admin/";

const fetchBinaryData = async (endpoint, params, customHeaders) => {
  const headers = { ...customHeaders };
  const searchParams = Object.entries(params || {})
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

  const url = `${endpoint}?${searchParams}`;

  return fetch(url, { method: "GET", headers })
    .then((res) => {
      return res.blob();
    })
    .then((res) => {
      res = URL.createObjectURL(res);

      if (res.hasOwnProperty("statusCode")) {
        return { networkSuccess: false };
      } else {
        return res;
      }
    })
    .catch((e) => {
      return { networkSuccess: false };
    });
};
const rakServicesURL = "https://rakservices.ppro.ae/api/";

const postRakServicesData = async (endpoint, params, customHeaders) => {
  const searchParams = Object.entries(params || {})
    .map((pair) => pair.map(encodeURIComponent).join("="))
    .join("&");

  let url = `${rakServicesURL}${endpoint}?${searchParams}`;

  let headers = { accept: "application/json", ...customHeaders };
  headers["Content-Type"] = "application/x-www-form-urlencoded";

  var details = {
    APIKey:
      "4142436265376438373863323135373161326262333738396330656666396664646434434445",
    UserName: "wafaa@rakchamber.ae",
  };

  var formBody = [];
  for (var property in details) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(details[property]);
    formBody.push(encodedKey + "=" + encodedValue);
  }
  formBody = formBody.join("&");
  return fetch(url, {
    method: "POST",
    headers,
    body: formBody,
  })
    .then((res) => res.json())
    .then((res) => ({ networkSuccess: true, ...res }))
    .catch((e) => ({ networkSuccess: false }));
};

export { fetchData, postData, postRakServicesData, fetchBinaryData };
