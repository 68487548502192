import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Dialog,
  Box,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Grid,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import useStyles from "../../../styles/components/shared/exitNotification/exitNotification";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
  const { t } = useTranslation();
  const history = useHistory();

  const [exit, setExit] = useState({
    open: false,
    url: "",
  });

  const handleClose = useCallback(() => {
    setExit((prevState) => ({
      ...prevState,
      open: false,
    }));
  }, []);

  const exitHandler = (e) => {
    const target = e.target;
    const element = target.closest(`a`);
    if (element) {
      if (!element.hasAttribute("href")) return;
      if (element.hasAttribute("download")) return;
      const href = element.getAttribute("href");

      if (!href.startsWith("http")) {
        history.pushState(null, null, href);
        // history.push(href);
        return;
      }

      if (href.includes("api/file/download")) {
        history.pushState(null, null, href);
        return;
      }

      e.preventDefault();
      setExit({
        url: href,
        open: true,
      });
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", exitHandler, false);
  }, []);

  const handleYes = () => {
    //window.location.href = url;
    window.open(exit.url);
    handleClose();
  };

  const classes = useStyles();

  return (
    <Dialog
      open={exit.open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      className={classes.root}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box className="close" onClick={handleClose}>
            X
          </Box>
        </Grid>
        <Grid item container>
          <Grid xs={12} className="content">
            <DialogTitle>{t("EXITMESSAGE.HINT")}</DialogTitle>
            <DialogContent className={classes.contentContainer}>
              <DialogContentText id="alert-dialog-slide-description">
                {t("EXITMESSAGE.TITLE")}
              </DialogContentText>
              <Box className="btnContainer">
                <Button onClick={handleYes}>{t("EXITMESSAGE.YES")}</Button>
                <Button onClick={handleClose}>{t("EXITMESSAGE.NO")}</Button>
              </Box>
            </DialogContent>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
}
