export const updateReqpayload = (values, type, files) => {
  let responsorTypesValue = [];
  if (values?.email) responsorTypesValue.push("email");
  if (values?.phone) responsorTypesValue.push("phone");
  switch (type) {
    case "service/request/booking":
      return {
        message: values?.message,
        responseTypes: responsorTypesValue,
        startingPrice: values.startingPrice,
        extraData: values?.extraData,
        reservations: [
          {
            facilites: values?.facilites,
            roomIds: values?.roomIds?.map((item) =>
              typeof item === "object" ? item?.id : item
            ),
            startDate: values?.startDate,
            endDate: values?.endDate,
            checkInDate: values?.checkInDate,
            checkOutDate: values?.checkOutDate,
            anySpeaker: values?.anySpeaker,
            exhibitionStudyIds: values.exhibitionStudyIds,
            exhibitionFloorIds: values.exhibitionFloorIds,
            exhibitorsNamesIds: values.exhibitorsNamesIds,
            economicPermitIds: values.economicPermitIds,
            exhibitionName: values?.exhibitionName,
            exhibitionCategory: values?.exhibitionCategory,
            startingPrice: values.startingPrice,
          },
        ],
      };

    default:
      break;
  }
  return { updateReqpayload };
};
export default updateReqpayload;
