export const makeTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",
    palette: {
      background: {
        default: "#fff",
      },

      primary: {
        main: currentTheme.elements.colors.primary,
      },
      secondary: {
        main: currentTheme.elements.colors.secondary,
      },
      textSecondary: {
        main: currentTheme.elements.colors.textSecondary,
      },
      cards: {
        main: currentTheme.elements.colors.cards,
      },
    },
    globals: currentTheme.elements,
  };
};
export const darkTheme = ({ currentTheme, isRTL, isDark }) => {
  return {
    direction: isRTL ? "rtl" : "ltr",

    palette: {
      type: "dark",
      background: {
        default: "#071a2f",
      },
      primary: {
        main: "#47799C",
      },
      secondary: {
        main: "#f52b3b",
      },
      textSecondary: {
        main: "#888",
      },
      cards: {
        main: "#E59150",
      },
    },
    globals: {
      type: "light",
      direction: "ltr",
      fontFamily: { ar: "next", en: "poppins" },
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      fontSizeG: [28, 24, 20, 16, 12],
      colors: {
        white: "#ffffff",
        primary: "#47799C",
        textSecondary: "#888",
        secondary: "#E63C49",
        tertiary: "#253F51",
        textDark: "#ffffff",
        textGeneral: "#dee2ed",
        lightBackground: "#E1E1E1",
      },
    },
  };
};
