import actions from "../../actions";
import { combineReducers } from "redux";

const {
  GET_ALL_POSTS,
  GET_ALL_POSTS2,
  ALL_POSTS_RETURNED,
  ALL_POSTS_RETURNED2,
  GET_POST_BY_ID,
  POST_BY_ID_RETURNED,
  GET_POST_BY_ALIAS,
  BY_ALIAS_RETURNED,
  GET_SPACE_BY_ALIAS,
  SPACE_BY_ALIAS_RETURNED,
  GET_POST_BY_CATEGORY,
  ALL_POSTS_BY_CATEGORY_RETURNED,
} = actions;

export const getAllPosts = (state = false, action) => {
  switch (action.type) {
    case GET_ALL_POSTS:
      return true;
    case ALL_POSTS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allPostsReturned = (state = false, action) => {
  switch (action.type) {
    case ALL_POSTS_RETURNED:
      if (action.data.length) {
        let sort = action.data[0].type;

        return {
          ...state,
          [sort]: action.data,
        };
      } else {
        return state;
      }

    default:
      return state;
  }
};

export const getAllPosts2 = (state = false, action) => {
  switch (action.type) {
    case GET_ALL_POSTS2:
      return true;
    case ALL_POSTS_RETURNED2:
      return false;
    default:
      return state;
  }
};
export const allPostsReturned2 = (state = false, action) => {
  switch (action.type) {
    case ALL_POSTS_RETURNED2:
      if (action.data?.length) {
        let sort = action.data[0].type;

        return {
          ...state,
          [sort]: action.data,
        };
      } else {
        return false;
      }

    default:
      return state;
  }
};

export const getPostById = (state = false, action) => {
  switch (action.type) {
    case GET_POST_BY_ID:
      return true;
    case POST_BY_ID_RETURNED:
      return false;
    default:
      return state;
  }
};
export const postByIdReturned = (state = false, action) => {
  switch (action.type) {
    case POST_BY_ID_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getPostByAlias = (state = false, action) => {
  switch (action.type) {
    case GET_POST_BY_ALIAS:
      return true;
    case BY_ALIAS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const postByAliasReturned = (state = false, action) => {
  switch (action.type) {
    case BY_ALIAS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getSpaceByAlias = (state = false, action) => {
  switch (action.type) {
    case GET_SPACE_BY_ALIAS:
      return true;
    case SPACE_BY_ALIAS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const spaceByAliasReturned = (state = false, action) => {
  switch (action.type) {
    case SPACE_BY_ALIAS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getPostByCategory = (state = false, action) => {
  switch (action.type) {
    case GET_POST_BY_CATEGORY:
      return true;
    case ALL_POSTS_BY_CATEGORY_RETURNED:
      return false;
    default:
      return state;
  }
};
export const allPostByCategoryReturned = (state = false, action) => {
  switch (action.type) {
    case ALL_POSTS_BY_CATEGORY_RETURNED:
      if (action.data?.success) {
        return {
          ...state,
          ["data"]: action.data,
        };
      } else {
        return false;
      }

    default:
      return state;
  }
};

export default combineReducers({
  getAllPosts,
  allPostsReturned,
  getAllPosts2,
  allPostsReturned2,
  getPostById,
  postByIdReturned,
  getPostByAlias,
  postByAliasReturned,
  getSpaceByAlias,
  spaceByAliasReturned,
  getPostByCategory,
  allPostByCategoryReturned,
});
