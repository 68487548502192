import Box from "@material-ui/core/Box";
import React, { memo } from "react";
import { BiChat } from "react-icons/bi";
import { Link } from "react-router-dom";

function FloatingBtns() {
  return (
    <Box className="btnsContainer">
      <Link to="/contactus">
        <Box component="span">
          <BiChat />
        </Box>
      </Link>

      <Box component="span">
        <a href="https://wa.me/971569974000" target="_blank" rel="noreferrer">
          <img src="/assets/icons/whats.png" alt="logo" />
        </a>
      </Box>
    </Box>
  );
}

export default memo(FloatingBtns);
