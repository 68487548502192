import { put, takeLatest, call } from "redux-saga/effects";
import {
  fetchServiceRequestsList,
  fetchRequestDetails,
  updateRequestStatus,
  fetchRequestStatusChanges,
  addRequestNote,
  fetchRequestNotes,
  createReply,
  fetchRequestAttachements,
  fetchRequestReplies,
  generateQRCode,
  markNotification,
} from "../../network/services";
import actions from "../../actions";

const {
  GET_REQUESTS_LIST,
  getRequestsListDone,
  GET_REQUEST_DETAILS,
  getRequestDetailsDone,
  UPDATE_REQUEST_STATUS,
  updateRequestStatusDone,
  GET_REQUEST_STATUS_CHANGES,
  getRequestStatusChangesDone,
  ADD_REQUEST_NOTE,
  addRequestNoteDone,
  GET_REQUEST_NOTES,
  getRequestNotesDone,
  SEND_REPLY,
  sendReplyDone,
  GET_REQUEST_ATTACHMENTS,
  getRequestAttachmentsDone,
  GET_REQUEST_REPLIES,
  getRequestRepliesDone,
  GET_QRCODE,
  getQrcodeDone,
  MARK_NOTIFICATION,
  notificationMarked,
} = actions;

function* performFetchRequestsList({ id }) {
  const result = yield call(fetchServiceRequestsList, id);

  if (result.networkSuccess && result.res.requests && result.res.requests[0]) {
    yield put(
      getRequestsListDone({
        data: result.res.requests,
      })
    );
  } else {
    yield put(getRequestsListDone({ data: [] }));
  }
}
export function* watchFetchRequestsList() {
  yield takeLatest(GET_REQUESTS_LIST, performFetchRequestsList);
}

function* performFetchRequestDetails({ id }) {
  const result = yield call(fetchRequestDetails, id);

  if (result.networkSuccess && result.res && result.res) {
    yield put(
      getRequestDetailsDone({
        data: result.res,
      })
    );
  } else {
    yield put(getRequestDetailsDone({ data: [] }));
  }
}
export function* watchFetchRequestsDetails() {
  yield takeLatest(GET_REQUEST_DETAILS, performFetchRequestDetails);
}

function* performUpdateRequestStatus({ data }) {
  try {
    const result = yield call(updateRequestStatus, data);
    if (result.networkSuccess)
      yield put(updateRequestStatusDone({ data: result.data }));
    else yield put(updateRequestStatusDone({ data: result.data }));
  } catch (e) {
    yield put(updateRequestStatusDone({ data: { success: false } }));
    return;
  }
}
export function* watchUpdateRequestStatus() {
  yield takeLatest(UPDATE_REQUEST_STATUS, performUpdateRequestStatus);
}

function* performFetchRequestStatusChanges({ id }) {
  try {
    const result = yield call(fetchRequestStatusChanges, id);
    if (result.networkSuccess && result.res.changes && result.res.changes[0])
      yield put(getRequestStatusChangesDone({ data: result.res.changes }));
    else yield put(getRequestStatusChangesDone({ data: [] }));
  } catch (e) {
    yield put(getRequestStatusChangesDone({ data: { success: false } }));
    return;
  }
}
export function* watchFetchRequestStatusChanges() {
  yield takeLatest(
    GET_REQUEST_STATUS_CHANGES,
    performFetchRequestStatusChanges
  );
}

function* performAddRequestNote({ data }) {
  try {
    const result = yield call(addRequestNote, data);
    if (result.networkSuccess)
      yield put(addRequestNoteDone({ data: result.data }));
    else yield put(addRequestNoteDone({ data: result.data }));
  } catch (e) {
    yield put(addRequestNoteDone({ data: { success: false } }));
    return;
  }
}
export function* watchAddRequestNote() {
  yield takeLatest(ADD_REQUEST_NOTE, performAddRequestNote);
}

function* performFetchRequestNotes({ id }) {
  try {
    const result = yield call(fetchRequestNotes, id);

    if (result.networkSuccess && result.res.notes && result.res.notes[0])
      yield put(getRequestNotesDone({ data: result.res.notes }));
    else yield put(getRequestNotesDone({ data: [] }));
  } catch (e) {
    yield put(getRequestNotesDone({ data: { success: false } }));
    return;
  }
}
export function* watchFetchRequestNotes() {
  yield takeLatest(GET_REQUEST_NOTES, performFetchRequestNotes);
}

function* performSendReply({ data }) {
  try {
    const result = yield call(createReply, data);
    if (result.networkSuccess) yield put(sendReplyDone({ data: result.data }));
    else yield put(sendReplyDone({ data: result.data }));
  } catch (e) {
    yield put(sendReplyDone({ data: { success: false } }));
    return;
  }
}
export function* watchSendReply() {
  yield takeLatest(SEND_REPLY, performSendReply);
}

function* performFetchRequestAttachments({ id }) {
  try {
    const result = yield call(fetchRequestAttachements, id);
    if (result.networkSuccess && result.res.attachments)
      yield put(getRequestAttachmentsDone({ data: result.res.attachments }));
    else yield put(getRequestAttachmentsDone({ data: [] }));
  } catch (e) {
    yield put(getRequestAttachmentsDone({ data: { success: false } }));
    return;
  }
}
export function* watchFetchRequestAttachments() {
  yield takeLatest(GET_REQUEST_ATTACHMENTS, performFetchRequestAttachments);
}

function* performFetchRequestReplies({ id }) {
  try {
    const result = yield call(fetchRequestReplies, id);

    if (result.networkSuccess && result.res.replays && result.res.replays[0])
      yield put(getRequestRepliesDone({ data: result.res.replays }));
    else yield put(getRequestRepliesDone({ data: [] }));
  } catch (e) {
    yield put(getRequestRepliesDone({ data: { success: false } }));
    return;
  }
}
export function* watchFetchRequestReplies() {
  yield takeLatest(GET_REQUEST_REPLIES, performFetchRequestReplies);
}

function* performQrCode(action) {
  try {
    const data = action.data;

    const result = yield call(generateQRCode, data);

    if (result && result.networkSuccess && result.data.success)
      yield put(getQrcodeDone({ data: result.data }));
    else yield put(getQrcodeDone({ data: [] }));
  } catch (error) {
    console.error(error);
    yield put(getQrcodeDone({ data: [] }));
  }
}

export function* watchQrCode() {
  yield takeLatest(GET_QRCODE, performQrCode);
}

function* performMarkNotification(action) {
  try {
    let data = { ids: action?.data };
    const result = yield call(markNotification, data);
    if (result.data.success)
      yield put(notificationMarked({ data: result.data }));
    else yield put(notificationMarked({ data: [] }));
  } catch (e) {
    yield put(notificationMarked({ data: "" }));
    return;
  }
}
export function* watchMarkNotification() {
  yield takeLatest(MARK_NOTIFICATION, performMarkNotification);
}
