import { put, takeLatest, takeEvery, call } from "redux-saga/effects";
import {
  getCategories,
  getCategoryById,
  getCategoryByAlias,
  getCategoryTreeByAlias,
  getCategoriesByYears,
} from "../../network/category";
import actions from "../../actions";

const {
  GET_CATEGORIES,
  categoriesReturned,
  GET_CATEGORY_BY_ID,
  categoryByIdReturned,
  GET_CATEGORY_BY_ALIAS,
  byAliasReturned,
  GET_CATEGORY_TREE_BY_ALIAS,
  categoryTreeReturned,
  GET_CATEGORIES_BY_YEARS,
  categoriesByYearsReturned,
} = actions;

function* performGetCategories(action) {
  try {
    const {
      sort: type,
      isFeatured,
      last3Years,
      language,
      subType,
      limit,
      offset,
    } = action;
    let result;
    if (!limit && !offset) {
      let limit = 1000;
      let offset = 0;
      result = yield call(
        getCategories,
        type,
        isFeatured,
        last3Years,
        language,
        subType,
        limit,
        offset
      );
    } else
      result = yield call(
        getCategories,
        type,
        isFeatured,
        last3Years,
        language,
        subType,
        limit,
        offset
      );

    if (result) yield put(categoriesReturned({ data: result.res }));
    else yield put(categoriesReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCategories() {
  yield takeEvery(GET_CATEGORIES, performGetCategories);
}

function* performGetCategoryById(action) {
  try {
    const { id, language } = action;

    const result = yield call(getCategoryById, id, language);
    if (result) yield put(categoryByIdReturned({ data: result.res }));
    else yield put(categoryByIdReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCategoryById() {
  yield takeEvery(GET_CATEGORY_BY_ID, performGetCategoryById);
}

function* performGetCategoryByAlias(action) {
  try {
    const { alias, language } = action;
    const result = yield call(getCategoryByAlias, alias, language);
    if (result) yield put(byAliasReturned({ data: result.res }));
    else yield put(byAliasReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCategoryByAlias() {
  yield takeEvery(GET_CATEGORY_BY_ALIAS, performGetCategoryByAlias);
}

function* performGetCategoryTree(action) {
  try {
    const { alias } = action;

    const result = yield call(getCategoryTreeByAlias, alias);
    if (result) yield put(categoryTreeReturned({ data: result.res.menuItems }));
    else yield put(categoryTreeReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCategoryTree() {
  yield takeEvery(GET_CATEGORY_TREE_BY_ALIAS, performGetCategoryTree);
}

function* performGetCategoriesByYears(action) {
  try {
    const { years, language } = action;
    const result = yield call(getCategoriesByYears, years, language);
    if (result) yield put(categoriesByYearsReturned({ data: result.res }));
    else yield put(categoriesByYearsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCategoriesByYears() {
  yield takeEvery(GET_CATEGORIES_BY_YEARS, performGetCategoriesByYears);
}
