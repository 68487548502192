import actions from "../../actions";
import { combineReducers } from "redux";

const {
  DO_SEARCH,
  DO_SEARCH_RETURNED,
  SEARCH_MEDIA,
  SEARCH_MEDIA_RETURNED,
  SEARCH,
  SEARCH_RETURNED,
} = actions;

export const searchData = (state = false, action) => {
  switch (action.type) {
    case DO_SEARCH:
      return action.data;
    default:
      return state;
  }
};
export const searchDataReturned = (state = false, action) => {
  switch (action.type) {
    case DO_SEARCH_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const searchMedia = (state = false, action) => {
  switch (action.type) {
    case SEARCH_MEDIA:
      return true;
    case SEARCH_MEDIA_RETURNED:
      return false;
    default:
      return state;
  }
};
export const searchMediaReturned = (state = false, action) => {
  switch (action.type) {
    case SEARCH_MEDIA_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const search = (state = false, action) => {
  switch (action.type) {
    case SEARCH:
      return true;
    case SEARCH_RETURNED:
      return false;
    default:
      return state;
  }
};
export const searchReturned = (state = false, action) => {
  switch (action.type) {
    case SEARCH_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  searchData,
  searchDataReturned,
  searchMedia,
  searchMediaReturned,
  search,
  searchReturned,
});
