import { put, takeLatest, call } from "redux-saga/effects";
import {
  search,
  searchVideos,
  searchPhotos,
  searchPublications,
  searchEvents,
  generalSearch,
} from "../../network/search";
import actions from "../../actions";

const {
  DO_SEARCH,
  doSearchReturned,
  SEARCH_MEDIA,
  searchMediaReturned,
  SEARCH,
  searchReturned,
} = actions;

function* performSearch(action) {
  try {
    let { data, language } = action;
    const result = yield call(search, data, language);
    if (result) yield put(doSearchReturned({ data: result.res }));
    else yield put(doSearchReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchSearch() {
  yield takeLatest(DO_SEARCH, performSearch);
}

function* performGeneralSearch(action) {
  try {
    let { sort, data, language } = action;
    const result = yield call(generalSearch, sort, data, language);

    if (result) yield put(searchReturned({ data: result.res }));
    else yield put(searchReturned({ data: [] }));
  } catch (error) {
    console.error(error);
    yield put(searchReturned({ data: [] }));
  }
}
export function* watchGeneralSearch() {
  yield takeLatest(SEARCH, performGeneralSearch);
}

function* performSearchMedia(action) {
  try {
    let { data, language, mediaType } = action;

    const result = yield call(
      mediaType == "videos"
        ? searchVideos
        : mediaType == "photos"
        ? searchPhotos
        : mediaType == "publications"
        ? searchPublications
        : mediaType == "events"
        ? searchEvents
        : search,
      data,
      language
    );
    if (result) yield put(searchMediaReturned({ data: result.res }));
    else yield put(searchMediaReturned({ data: [] }));
  } catch (error) {
    console.error(error);
    yield put(searchMediaReturned({ data: [] }));
  }
}
export function* watchSearchMedia() {
  yield takeLatest(SEARCH_MEDIA, performSearchMedia);
}
