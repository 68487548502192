import { Box, Paper } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import {
  createTheme,
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import rtl from "jss-rtl";
import React, {
  lazy,
  memo,
  Suspense,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import ExitNotification from "../components/shared/exitNotification/ExitNotification";
import FloatingBtns from "../components/shared/floatingBtns/FloatingBtns";
import actions from "../redux/actions";
import Spinner from "./Spinner";
import { darkTheme, makeTheme } from "../styles/globalTheme/makeTheme";

const { getMenu, getPageDetails, getData } = actions;

const Header = lazy(() =>
  import(/* webpackChunkName: "header"*/ "../components/header/Header")
);
const Footer = lazy(() =>
  import(/* webpackChunkName: "footer"*/ "../components/footer/Footer")
);
const Home = lazy(() => import(/* webpackChunkName: "Home"*/ "./Home"));
const ServicesCardsPage = lazy(() =>
  import(
    /* webpackChunkName: "services-cards"*/ "../components/services/servicesCardsTabs/ServicesCardsTabs"
  )
);
const Sitemap = lazy(() =>
  import(/* webpackChunkName: "sitemap"*/ "./sitemap/Sitemap")
);

//services
const ReadFile = lazy(() =>
  import(
    /* webpackChunkName: "PdfViewer"*/ "../components/services/dashboard/PdfViewer"
  )
);

const ForgetPassword = lazy(() =>
  import(
    /* webpackChunkName: "ForgetPasswrord"*/ "../components/services/ForgetPasswrord"
  )
);
const MyDashboard = lazy(() =>
  import(
    /* webpackChunkName: "MyDashboard"*/ "../components/services/dashboard/MyDashboard"
  )
);

const LogIn = lazy(() =>
  import(/* webpackChunkName: "LogIn"*/ "../components/services/LogIn")
);
const SignUp = lazy(() =>
  import(/* webpackChunkName: "SignUp"*/ "../components/services/SignUp")
);
const Confirmation = lazy(() =>
  import(
    /* webpackChunkName: "ConfirmationCode"*/ "../components/services/ConfirmationCode"
  )
);

const ServiceCardDetails = lazy(() =>
  import(
    /* webpackChunkName: "ServicesCardDetails"*/ "../components/services/ServicesCardDetails"
  )
);

const TermsAndConditions = lazy(() =>
  import(
    /* webpackChunkName: "TermsandConditions"*/ "../components/footer/TermsandConditions"
  )
);

const PrivacyPolicy = lazy(() =>
  import(
    /* webpackChunkName: "PrivacyPolicy"*/ "../components/footer/PrivacyPolicy"
  )
);

const Search = lazy(() => import(/* webpackChunkName: "Search"*/ "./Search"));
const Error = lazy(() =>
  import(/* webpackChunkName: "Error"*/ "../components/shared/error/Error")
);

const GeneralPage = lazy(() =>
  import(
    /* webpackChunkName: "GeneralPage"*/ "../components/generalPage/GeneralPage"
  )
);
const DetailedGeneralPage = lazy(() =>
  import(
    /* webpackChunkName: "GeneralPageDetails"*/ "../components/generalPage/GeneralPageDetails"
  )
);
const Thanks = lazy(() =>
  import(/* webpackChunkName: "Thanks"*/ "../components/shared/thanks/Thanks")
);
const Services = lazy(() =>
  import(
    /* webpackChunkName: "ServicesCards"*/ "../components/services/ServicesCards"
  )
);
const EventSponsors = lazy(() =>
  import(
    /* webpackChunkName: "EventSponsor"*/ "../components/services/EventSponsor/EventSponsor"
  )
);
const SponsorForm = lazy(() =>
  import(
    /* webpackChunkName: "SponsorForm"*/ "../components/services/EventSponsor/SponsorForm"
  )
);
const Tenders = lazy(() =>
  import(
    /* webpackChunkName: "Tenders"*/ "../components/services/tenders/Tenders"
  )
);
const TenderDetails = lazy(() =>
  import(
    /* webpackChunkName: "TenderDetails"*/ "../components/services/tenders/TenderDetails"
  )
);
const TenderForm = lazy(() =>
  import(
    /* webpackChunkName: "TenderForm"*/ "../components/services/tenders/TenderForm"
  )
);
const PermitsForm = lazy(() =>
  import(
    /* webpackChunkName: "PermitsForm"*/ "../components/services/permits/PermitsForm"
  )
);
const EventSponsorDetails = lazy(() =>
  import(
    /* webpackChunkName: "EventSponsorDetails"*/ "../components/services/EventSponsor/EventSponsorDetails"
  )
);
const ContactUs = lazy(() =>
  import(/* webpackChunkName: "ContactUs"*/ "../routes/contactUs/ContactUs")
);
const EventDetails = lazy(() =>
  import(/* webpackChunkName: "EventDetails"*/ "../routes/events/EventDetails")
);
const EventsTabs = lazy(() =>
  import(/* webpackChunkName: "EventsTabs"*/ "../routes/events/EventsTabs")
);
const AirTransport = lazy(() =>
  import(
    /* webpackChunkName: "AirTransport"*/ "../components/exploreRak/AirTransport"
  )
);
const Hotels = lazy(() =>
  import(/* webpackChunkName: "Hotels"*/ "../components/exploreRak/Hotels")
);
const Tourist = lazy(() =>
  import(
    /* webpackChunkName: "Tourist"*/ "../components/exploreRak/tourist/Tourist"
  )
);
const Restaurants = lazy(() =>
  import(
    /* webpackChunkName: "Restaurants"*/ "../components/exploreRak/Restaurants"
  )
);
const EntertainmentActivities = lazy(() =>
  import(
    /* webpackChunkName: "EntertainmentActivities"*/ "../components/exploreRak/EntertainmentActivities"
  )
);
const PublicTrasportation = lazy(() =>
  import(
    /* webpackChunkName: "PublicTrasportation"*/ "../components/exploreRak/Publictrasport/PublicTrasportation"
  )
);
const About = lazy(() =>
  import(/* webpackChunkName: "About"*/ "../components/exploreRak/About")
);
const ExploreRak = lazy(() =>
  import(/* webpackChunkName: "ExploreRak"*/ "../routes/ExploreRak")
);
const AboutEx = lazy(() =>
  import(/* webpackChunkName: "About"*/ "../routes/explore/about/About")
);
const CareerForm = lazy(() =>
  import(
    /* webpackChunkName: "CareerForm"*/ "../routes/explore/careers/CareerForm"
  )
);
const Careers = lazy(() =>
  import(/* webpackChunkName: "Careers"*/ "../routes/explore/careers/Careers")
);
const SponsorDetails = lazy(() =>
  import(
    /* webpackChunkName: "SponsorDetails"*/ "../routes/explore/sponsor/SponsorDetails"
  )
);
const SponsorContainer = lazy(() =>
  import(
    /* webpackChunkName: "SponsorContainer"*/ "../routes/explore/sponsor/SponsorContainer"
  )
);
const PartnerDetails = lazy(() =>
  import(
    /* webpackChunkName: "PartnerDetails"*/ "../routes/explore/partners/PartnerDetails"
  )
);
const PartnersContainer = lazy(() =>
  import(
    /* webpackChunkName: "PartnersContainer"*/ "../routes/explore/partners/PartnersContainer"
  )
);
const SupportingContainer = lazy(() =>
  import(
    /* webpackChunkName: "SupportingContainer"*/ "../routes/explore/supporting/SupportingContainer"
  )
);
const EParticipationPolicy = lazy(() =>
  import(
    /* webpackChunkName: "EParticipationPolicy"*/ "../routes/participation/policy/EParticipationPolicy"
  )
);
const Explore = lazy(() =>
  import(/* webpackChunkName: "Explore"*/ "../routes/explore/Explore")
);
const Ideas = lazy(() =>
  import(/* webpackChunkName: "Ideas"*/ "../routes/participation/ideas/Ideas")
);
const FAQ = lazy(() =>
  import(/* webpackChunkName: "FAQ"*/ "../routes/participation/faq/FAQ")
);
const SurveyContainer = lazy(() =>
  import(
    /* webpackChunkName: "SurveyContainer"*/ "../routes/participation/survey/SurveyContainer"
  )
);
const AllSurveys = lazy(() =>
  import(
    /* webpackChunkName: "AllSurveys"*/ "../routes/participation/survey/AllSurveys"
  )
);
const PollContainer = lazy(() =>
  import(
    /* webpackChunkName: "PollContainer"*/ "../routes/participation/polls/PollContainer"
  )
);
const EParticapation = lazy(() =>
  import(
    /* webpackChunkName: "EParticapation"*/ "../routes/participation/EParticapation"
  )
);
const OpinionsDetails = lazy(() =>
  import(
    /* webpackChunkName: "OpinionsDetails"*/ "../routes/participation/opinions/OpinionsDetails"
  )
);
const OpinionsContainer = lazy(() =>
  import(
    /* webpackChunkName: "OpinionsContainer"*/ "../routes/participation/opinions/OpinionsContainer"
  )
);
const PublicationRead = lazy(() =>
  import(
    /* webpackChunkName: "PublicationRead"*/ "../components/shared/publicationsCard/PublicationRead"
  )
);
const VideoGalleryDetails = lazy(() =>
  import(
    /* webpackChunkName: "VideoGalleryDetails"*/ "../components/mediaCenter/videoGallery/VideoGalleryDetails"
  )
);
const VideoGallery = lazy(() =>
  import(
    /* webpackChunkName: "VideoGallery"*/ "../components/mediaCenter/videoGallery/VideoGallery"
  )
);
const NewsDetails = lazy(() =>
  import(
    /* webpackChunkName: "NewsDetails"*/ "../components/mediaCenter/news/NewsDetails"
  )
);
const PhotoGalleryDetails = lazy(() =>
  import(
    /* webpackChunkName: "PhotoGalleryDetails"*/ "../components/mediaCenter/photoGallery/PhotoGalleryDetails"
  )
);
const PhotoGallery = lazy(() =>
  import(
    /* webpackChunkName: "PhotoGallery"*/ "../components/mediaCenter/photoGallery/PhotoGallery"
  )
);
const Publications = lazy(() =>
  import(
    /* webpackChunkName: "Publications"*/ "../components/mediaCenter/publications/Publications"
  )
);
const BlogsDetails = lazy(() =>
  import(
    /* webpackChunkName: "BlogsDetails"*/ "../components/mediaCenter/blogs/BlogsDetails"
  )
);
const Blogs = lazy(() =>
  import(/* webpackChunkName: "Blogs"*/ "../components/mediaCenter/blogs/Blogs")
);
const News = lazy(() =>
  import(/* webpackChunkName: "News"*/ "../components/mediaCenter/news/News")
);
const MediaCenter = lazy(() =>
  import(/* webpackChunkName: "MediaCenter"*/ "../routes/MediaCenter")
);
const BookVenue = lazy(() =>
  import(
    /* webpackChunkName: "BookingForm"*/ "../components/services/booking/BookingForm"
  )
);
const DisplayReservation = lazy(() =>
  import(
    /* webpackChunkName: "DisplayReservation"*/ "../components/services/booking/DisplayReservation"
  )
);
const BookSpace = lazy(() =>
  import(
    /* webpackChunkName: "BookSpace"*/ "../components/services/bookSpace/BookSpace"
  )
);
const EventSpaceDetails = lazy(() =>
  import(
    /* webpackChunkName: "EventSpaceDetails"*/ "../components/services/bookSpace/EventSpaceDetails"
  )
);
const EventsSpaces = lazy(() =>
  import(
    /* webpackChunkName: "EventsSpaces"*/ "../components/services/bookSpace/EventsSpaces"
  )
);
const VenueDetails = lazy(() =>
  import(
    /* webpackChunkName: "VenueDetails"*/ "../components/services/booking/VenueDetails"
  )
);
const Venues = lazy(() =>
  import(
    /* webpackChunkName: "Venues"*/ "../components/services/booking/Venues"
  )
);
const MainExplore = lazy(() =>
  import(/* webpackChunkName: "MainExplore"*/ "../routes/explore/MainExplore")
);
const PageFiles = lazy(() =>
  import(
    /* webpackChunkName: "PageFiles"*/ "../components/shared/pageFiles/PageFiles"
  )
);
const Ad = lazy(() =>
  import(/* webpackChunkName: "Ad"*/ "../components/shared/ad/Ad")
);
const AdditionalInfo = lazy(() =>
  import(
    /* webpackChunkName: "additionalInfo"*/ "../components/shared/additionalInfo/AdditionalInfo"
  )
);
const PageGenerator = lazy(() =>
  import(
    /* webpackChunkName: "pageGenerator"*/ "../components/shared/pageGenerator/PageGenerator"
  )
);

function Routes() {
  const location = useLocation();
  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [location.pathname]);
  const {
    basicTheme,
    basicTheme: { isRTL, isDark },
  } = useSelector((state) => state.theme_reducer);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const { url } = useRouteMatch();
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
  const theme = isDark ? darkTheme(basicTheme) : makeTheme(basicTheme);
  const materialTheme = createTheme(theme);
  const rakExUser = localStorage.getItem("rakExUser");
  const token = localStorage.getItem("token");
  const sessionToken = sessionStorage.getItem("rakExUserSession");
  let user;
  if (rakExUser) user = JSON.parse(rakExUser);
  const handleOnIdle = (event) => {
    localStorage.removeItem("token");
    localStorage.removeItem("rakExUser");
    sessionStorage.removeItem("rakExUserSession");
    sessionStorage.removeItem("profileSession");
    localStorage.removeItem("directURL");
    window.location = "/home";
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  // useEffect(() => {
  //   const url = window.location.href?.split("/");
  //   let correctUrl = url?.filter((item, index) => index !== 3);
  //   dispatch(getPageDetails({ data: correctUrl.join("/") }));
  // }, [window.location.href]);
  useEffect(() => {
    const useStyles = makeStyles((materialTheme) => ({
      floatingSocial: {
        position: "fixed",
        right: isRTL ? "unset" : 0,
        left: isRTL ? 0 : "unset",
        top: "55vh",
        zIndex: "1000",
        [materialTheme.breakpoints.down(750)]: {
          top: "52vh",
        },
        [materialTheme.breakpoints.down(500)]: {
          top: "47vh",
        },
        [materialTheme.breakpoints.down(390)]: {
          height: 90,
          width: 60,
        },
        [materialTheme.breakpoints.down(365)]: {
          height: 80,
          width: 50,
        },
        [materialTheme.breakpoints.down(340)]: {
          height: 70,
          width: 40,
        },
        "& div.btnsContainer": {
          height: 100,
          width: 70,
          background: theme.palette.secondary.main,

          clipPath: isRTL
            ? "polygon(0 0, 100% 0, 70% 100%, 0 100%)"
            : "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
          display: "grid",
          placeItems: "center",

          [materialTheme.breakpoints.down(750)]: {
            height: 80,
            width: 70,
          },
          [materialTheme.breakpoints.down(365)]: {
            height: 80,
            width: 55,
          },
          [materialTheme.breakpoints.down(340)]: {
            height: 80,
            width: 65,
            paddingLeft: theme.direction === "rtl" ? 16 : 0,
          },
          "& span": {
            cursor: "pointer",
            height: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginRight: isRTL ? 15 : 0,
            [materialTheme.breakpoints.down(340)]: {
              marginRight: 0,
            },
            "& a": {
              marginLeft: isRTL ? 0 : 20,
              [materialTheme.breakpoints.down(750)]: {
                height: 35,
                width: 32,
              },
              [materialTheme.breakpoints.down(365)]: {
                height: 35,
                width: 27,
                marginLeft: isRTL ? 0 : 6,
              },
              [materialTheme.breakpoints.down(340)]: {
                marginLeft: isRTL ? 0 : -6,
              },
            },
            "& svg": {
              fontSize: theme.globals.fontSize.xl + 4,
              color: theme.globals.colors.white,
              marginLeft: isRTL ? 0 : "26px",
              transform: "scale(1)",
              transition: "transform 0.4s",

              [materialTheme.breakpoints.down(750)]: {
                fontSize: theme.globals.fontSize.xl - 2,
              },
              [materialTheme.breakpoints.down(365)]: {
                fontSize: theme.globals.fontSize.s + 2,
              },
            },

            "&:hover svg": {
              transform: "scale(1.2)",
            },
          },

          "& span:first-of-type": {
            "& svg": {
              marginLeft: isRTL ? 0 : "13px",
              [materialTheme.breakpoints.down(365)]: {
                marginLeft: 0,
              },
              [materialTheme.breakpoints.down(340)]: {
                marginLeft: "-10px",
              },
            },
          },
        },
      },
    }));
  }, []);
  const useStyles = makeStyles((materialTheme) => ({
    floatingSocial: {
      position: "fixed",
      right: isRTL ? "unset" : 0,
      left: isRTL ? 0 : "unset",
      top: "55vh",
      zIndex: "1000",
      [materialTheme.breakpoints.down(750)]: {
        top: "52vh",
      },
      [materialTheme.breakpoints.down(500)]: {
        top: "47vh",
      },
      [materialTheme.breakpoints.down(390)]: {
        height: 90,
        width: 60,
      },
      [materialTheme.breakpoints.down(365)]: {
        height: 80,
        width: 50,
      },
      [materialTheme.breakpoints.down(340)]: {
        height: 70,
        width: 40,
      },
      "& div.btnsContainer": {
        height: 100,
        width: 70,
        background: theme.palette.secondary.main,

        clipPath: isRTL
          ? "polygon(0 0, 100% 0, 70% 100%, 0 100%)"
          : "polygon(0 0, 100% 0, 100% 100%, 30% 100%)",
        display: "grid",
        placeItems: "center",

        [materialTheme.breakpoints.down(750)]: {
          height: 80,
          width: 70,
        },
        [materialTheme.breakpoints.down(365)]: {
          height: 80,
          width: 55,
        },
        [materialTheme.breakpoints.down(340)]: {
          height: 80,
          width: 65,
          paddingLeft: theme.direction === "rtl" ? 16 : 0,
        },
        "& span": {
          cursor: "pointer",
          height: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginRight: isRTL ? 15 : 0,
          [materialTheme.breakpoints.down(340)]: {
            marginRight: 0,
          },
          "& a": {
            marginLeft: isRTL ? 0 : 20,
            [materialTheme.breakpoints.down(750)]: {
              height: 35,
              width: 32,
            },
            [materialTheme.breakpoints.down(365)]: {
              height: 35,
              width: 27,
              marginLeft: isRTL ? 0 : 6,
            },
            [materialTheme.breakpoints.down(340)]: {
              marginLeft: isRTL ? 0 : -6,
            },
          },
          "& svg": {
            fontSize: theme.globals.fontSize.xl + 4,
            color: theme.globals.colors.white,
            marginLeft: isRTL ? 0 : "26px",
            transform: "scale(1)",
            transition: "transform 0.4s",

            [materialTheme.breakpoints.down(750)]: {
              fontSize: theme.globals.fontSize.xl - 2,
            },
            [materialTheme.breakpoints.down(365)]: {
              fontSize: theme.globals.fontSize.s + 2,
            },
          },

          "&:hover svg": {
            transform: "scale(1.2)",
          },
        },

        "& span:first-of-type": {
          "& svg": {
            marginLeft: isRTL ? 0 : "13px",
            [materialTheme.breakpoints.down(365)]: {
              marginLeft: 0,
            },
            [materialTheme.breakpoints.down(340)]: {
              marginLeft: "-10px",
            },
          },
        },
      },
    },
  }));
  const classes = useStyles();
  const reducers = useSelector((state) => state);
  const [spinnerToggle, setSpinnerToggle] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (
      reducers?.crudReducers?.getDataAll ||
      reducers?.search?.search ||
      reducers?.crudReducers?.getByAlias ||
      reducers?.crudReducers?.getAll ||
      reducers?.category?.getCategories ||
      reducers?.filter?.getPublicationsYears ||
      reducers?.filter?.requestYearPosts ||
      reducers?.files?.downloadCountAction ||
      reducers?.files?.downloadFile ||
      reducers?.filter?.getEventsYears ||
      reducers?.posts_reducers?.getPostByAlias ||
      reducers?.posts_reducers?.getPostByCategory ||
      reducers?.category?.getCategoryByAlias ||
      reducers?.careers?.getCareerByAlias ||
      reducers?.careers?.postCareerApplication ||
      reducers?.crudReducers?.createNew ||
      reducers?.surveys?.submitSurvey ||
      reducers?.surveys?.getAnswersById ||
      reducers?.pagePicture?.getPagePicture ||
      reducers?.surveys?.getSurveyByAlias ||
      reducers?.sitemap?.reqSitemap ||
      reducers?.users?.confirmUser ||
      reducers?.users?.resetPasswordAction ||
      reducers?.users?.logInAction ||
      reducers?.users?.forgotPasswordAction ||
      reducers?.users?.activateAccount ||
      reducers?.APIServices?.getEservicesDetails ||
      reducers?.crudReducers?.getById ||
      reducers?.crudReducers?.cancelItem ||
      reducers?.crudReducers?.getReservations ||
      reducers?.crudReducers?.calculate ||
      reducers?.crudReducers?.createNewReservation ||
      reducers?.crudReducers?.createNewSpaceReservation ||
      reducers?.posts_reducers?.getSpaceByAlias ||
      reducers?.posts_reducers?.getAllPosts2 ||
      reducers?.crudReducers?.getNotifications ||
      reducers?.services?.markNotification ||
      reducers?.users?.updateUserAction ||
      reducers?.users?.signUpAction ||
      reducers?.crudReducers?.payment ||
      reducers?.services?.getRequestDetails ||
      reducers?.services?.getRequestStatusChanges ||
      reducers?.services?.getRequestNotes ||
      reducers?.services?.getRequestAttachments ||
      reducers?.services?.getRequestReplies ||
      reducers?.search?.doSearch ||
      reducers?.thanks.thanksAction
    ) {
      setSpinnerToggle(true);
    } else {
      setSpinnerToggle(false);
    }
  }, [reducers]);

  useEffect(() => {
    dispatch(getMenu());
  }, []);

  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={materialTheme}>
        <CssBaseline />
        <Paper
          ref={componentRef}
          display="flex"
          flex-direction="column"
          width="100%"
          dir={isRTL ? "rtl" : "ltr"}
          id="full-page"
          style={{
            background: materialTheme.palette.background.default,
            position: "relative",
            minHeight: "100vh",
            boxShadow: "none",
            display: "flex",
            flexDirection: "column",
            flex: "1 1 auto",
          }}
        >
          <Box className={classes.floatingSocial}>
            <FloatingBtns />
          </Box>
          <Suspense fallback={<h1></h1>}>
            <Header id="back-to-top-anchor" />
            <Switch>
              <Route exact path={`${url}/home`} render={() => <Home />} />
              <Route
                path={`${url}/search`}
                render={(props) => (
                  <>
                    <PageGenerator />
                    <Search {...props} />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/tenders`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <Tenders />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/rak-services`}
                render={() => (
                  <>
                    <PageGenerator />
                    <ServicesCardsPage />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/tenders/details/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <TenderDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/tenders/apply/:alias`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <TenderForm />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/permits`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <PermitsForm />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/venues`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Venues />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/venues/details/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <VenueDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/booking/Exhibition-Space-Booking`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EventsSpaces />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/Exhibition-Space-Booking/:alias/details`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EventSpaceDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/Exhibition-Space-Booking/:alias/apply`}
                render={() => (
                  <>
                    <PageGenerator /> <BookSpace />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/bookVenue/:alias/reservations`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <DisplayReservation />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/bookVenue/:alias`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <BookVenue />{" "}
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/media`}
                render={() => (
                  <>
                    <PageGenerator />
                    <MediaCenter />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/news`}
                render={() => (
                  <>
                    <PageGenerator />
                    <News />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/blogs`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Blogs />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/blogs/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <BlogsDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/about-rak-expo`}
                render={() => (
                  <>
                    <PageGenerator /> <AboutEx />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/publications`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Publications />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/photo-gallery`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PhotoGallery />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/photo-gallery/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PhotoGalleryDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/news/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <NewsDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/video-gallery`}
                render={() => (
                  <>
                    <PageGenerator /> <VideoGallery />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/video-gallery/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <VideoGalleryDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/media/publications/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PublicationRead />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/opinions`}
                render={() => (
                  <>
                    <PageGenerator /> <OpinionsContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/opinions/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <OpinionsDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EParticapation />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/polls`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PollContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/surveys`}
                render={() => (
                  <>
                    <PageGenerator />
                    <AllSurveys />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/surveys/:alias`}
                render={() => (
                  <>
                    <PageGenerator /> <SurveyContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/faq`}
                render={() => (
                  <>
                    <PageGenerator />
                    <FAQ />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/ideas`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Ideas />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/participation/eparticipationPolicy`}
                render={() => (
                  <>
                    <PageGenerator /> <EParticipationPolicy />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore`}
                render={() => (
                  <>
                    <PageGenerator />
                    <MainExplore />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex`}
                render={() => (
                  <>
                    <PageGenerator /> <Explore />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/about-rak-expo`}
                render={() => (
                  <>
                    <PageGenerator /> <AboutEx />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/supporting-services`}
                render={() => (
                  <>
                    <PageGenerator />
                    <SupportingContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/partners`}
                render={() => (
                  <>
                    <PageGenerator /> <PartnersContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/partners/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PartnerDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/sponsors`}
                render={() => (
                  <>
                    <PageGenerator />
                    <SponsorContainer />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/sponsors/:alias/:sponsoType?`}
                render={() => (
                  <>
                    <PageGenerator />
                    <SponsorDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/careers`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Careers />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-ex/careers/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <CareerForm />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/about`}
                render={() => (
                  <>
                    <PageGenerator /> <AboutEx />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak`}
                render={() => (
                  <>
                    <PageGenerator />
                    <ExploreRak />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/about`}
                render={() => (
                  <>
                    <PageGenerator />
                    <About />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/trasportation`}
                render={() => (
                  <>
                    <PageGenerator />
                    <PublicTrasportation />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/entertainment`}
                render={() => (
                  <>
                    <PageGenerator /> <EntertainmentActivities />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/restaurants`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Restaurants />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/tourist`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Tourist />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/hotels`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Hotels />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/explore/explore-rak/airTransport`}
                render={() => (
                  <>
                    <PageGenerator /> <AirTransport />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/events/:alias`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EventDetails />
                  </>
                )}
              />
              4
              <Route
                exact
                path={`${url}/events`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EventsTabs />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/contactus/:alias?`}
                render={() => (
                  <>
                    <PageGenerator />
                    <ContactUs />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/sitemap`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Sitemap />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/sponsor/:type/:alias/:id?`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <SponsorForm />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/read/file/:uuid`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <ReadFile />{" "}
                    </>
                  ) : (
                    <>
                      <PageGenerator /> <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/sponsorform/:type/:id?`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <SponsorForm />
                    </>
                  ) : (
                    <>
                      <PageGenerator />
                      <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/eventsponsor/:type/:alias/details/:defaultSponsor`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <EventSponsorDetails />
                    </>
                  ) : (
                    <>
                      <PageGenerator /> <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/eventsponsor/:type/:alias/details`}
                render={() => (
                  <>
                    <PageGenerator />
                    <EventSponsorDetails />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/sponsorform/:type/:id?`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <SponsorForm />{" "}
                    </>
                  ) : (
                    <>
                      <PageGenerator /> <LogIn />
                    </>
                  )
                }
              />
              <Route
                exact
                path={`${url}/services/eventsponsor/:type`}
                render={() => (
                  <>
                    <PageGenerator /> <EventSponsors />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/login`}
                render={() => (
                  <>
                    <PageGenerator />
                    <LogIn />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/forgetPassword`}
                render={() => (
                  <>
                    <PageGenerator />
                    <ForgetPassword />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/signup`}
                render={() => (
                  <>
                    <PageGenerator /> <SignUp />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/Confirmationcode`}
                render={() => (
                  <>
                    <PageGenerator /> <Confirmation />
                  </>
                )}
              />
              <Route
                path={`${url}/services/dashboard`}
                render={() =>
                  token || sessionToken ? (
                    <>
                      <PageGenerator />
                      <MyDashboard parentPg="services" />
                    </>
                  ) : (
                    <>
                      <PageGenerator /> <LogIn />
                    </>
                  )
                }
                // render={() => <MyDashboard />}
              />
              <Route
                exact
                path={`${url}/services/serviceDetails/:id/:url?/:type?`}
                render={() => (
                  <>
                    <PageGenerator /> <ServiceCardDetails parentPg="services" />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/termsAndConditions`}
                render={() => (
                  <>
                    <PageGenerator /> <TermsAndConditions parentPg="services" />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/privacyPolicy`}
                render={() => (
                  <>
                    <PageGenerator /> <PrivacyPolicy />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/thanks`}
                render={() => (
                  <>
                    <PageGenerator /> <Thanks />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/services/:tabname?`}
                render={() => (
                  <>
                    <PageGenerator />
                    <Services />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/section/:categoryAlias`}
                render={(props) => (
                  <>
                    <PageGenerator /> <GeneralPage {...props} />
                  </>
                )}
              />
              <Route
                exact
                path={`${url}/page/:pageAlias`}
                render={(props) => (
                  <>
                    <PageGenerator />
                    <DetailedGeneralPage {...props} />
                  </>
                )}
              />
              <Route
                render={() => (
                  <>
                    <PageGenerator /> <Error />
                  </>
                )}
              />
              {/* <Route
                  path={`${url}not-found`}
                  render={(props) => <Error404 {...props} />}
                />
                <Redirect to={`${url}not-found`} /> */}
            </Switch>
            <PageFiles />
            <Ad />
            <AdditionalInfo />
            <Footer />
            {spinnerToggle ? <Spinner /> : null}
          </Suspense>
          <ExitNotification />
        </Paper>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default memo(Routes);
