import actions from "../../actions";
import { combineReducers } from "redux";
const {
  GET_REQUESTS_LIST,
  GET_REQUESTS_LIST_DONE,
  GET_REQUEST_DETAILS,
  GET_REQUEST_DETAILS_DONE,
  UPDATE_REQUEST_STATUS,
  UPDATE_REQUEST_STATUS_DONE,
  UPDATE_REQUEST_STATUS_CLEAR,
  GET_REQUEST_STATUS_CHANGES,
  GET_REQUEST_STATUS_CHANGES_DONE,
  ADD_REQUEST_NOTE,
  ADD_REQUEST_NOTE_DONE,
  GET_REQUEST_NOTES,
  GET_REQUEST_NOTES_DONE,
  SEND_REPLY,
  SEND_REPLY_DONE,
  GET_REQUEST_ATTACHMENTS,
  GET_REQUEST_ATTACHMENTS_DONE,
  GET_REQUEST_REPLIES,
  GET_REQUEST_REPLIES_DONE,
  GET_QRCODE,
  GET_QRCODE_DONE,
  NOTIFICATION_REQUEST,
  NOTIFICATION_MARKED,
  CLEAR,
} = actions;

export const gettingRequestsList = (state = false, action) => {
  switch (action.type) {
    case GET_REQUESTS_LIST:
      return true;
    case GET_REQUESTS_LIST_DONE:
      return false;
    default:
      return state;
  }
};
export const requestsList = (state = false, action) => {
  switch (action.type) {
    case GET_REQUESTS_LIST_DONE:
      return action.data;
    default:
      return state;
  }
};
export const gettingRequestDetails = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_DETAILS:
      return true;
    case GET_REQUEST_DETAILS_DONE:
      return false;
    default:
      return state;
  }
};
export const requestDetails = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_DETAILS_DONE:
      return action.data;
    default:
      return state;
  }
};

export const updattingRequestStatus = (state = false, action) => {
  switch (action.type) {
    case UPDATE_REQUEST_STATUS:
      return true;
    case UPDATE_REQUEST_STATUS_DONE:
      return false;
    default:
      return state;
  }
};

export const requestStatusUpdated = (state = false, action) => {
  switch (action.type) {
    case UPDATE_REQUEST_STATUS_DONE:
      return action.data;
    case UPDATE_REQUEST_STATUS_CLEAR:
      return false;
    default:
      return state;
  }
};

export const gettingRequestStatusChanges = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_STATUS_CHANGES:
      return true;
    case GET_REQUEST_STATUS_CHANGES_DONE:
      return false;
    default:
      return state;
  }
};

export const requestStatusChanges = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_STATUS_CHANGES_DONE:
      return action.data;

    default:
      return state;
  }
};

export const addingRequestNote = (state = false, action) => {
  switch (action.type) {
    case ADD_REQUEST_NOTE:
      return true;
    case ADD_REQUEST_NOTE_DONE:
      return false;
    default:
      return state;
  }
};

export const requestNoteAdded = (state = false, action) => {
  switch (action.type) {
    case ADD_REQUEST_NOTE_DONE:
      return action.data;
    default:
      return state;
  }
};

export const getRequestNotes = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_NOTES:
      return true;
    case GET_REQUEST_NOTES_DONE:
      return false;
    default:
      return state;
  }
};

export const requestNotes = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_NOTES_DONE:
      return action.data;
    default:
      return state;
  }
};

export const sendingReply = (state = false, action) => {
  switch (action.type) {
    case SEND_REPLY:
      return true;
    case SEND_REPLY_DONE:
      return false;
    default:
      return state;
  }
};

export const replaySent = (state = false, action) => {
  switch (action.type) {
    case SEND_REPLY_DONE:
      return action.data;
    default:
      return state;
  }
};

export const getRequestAttachments = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_ATTACHMENTS:
      return true;
    case GET_REQUEST_ATTACHMENTS_DONE:
      return false;
    default:
      return state;
  }
};

export const requestAttachments = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_ATTACHMENTS_DONE:
      return action.data;
    default:
      return state;
  }
};

export const getRequestReplies = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_REPLIES:
      return true;
    case GET_REQUEST_REPLIES_DONE:
      return false;
    default:
      return state;
  }
};

export const requestReplies = (state = false, action) => {
  switch (action.type) {
    case GET_REQUEST_REPLIES_DONE:
      return action.data;
    default:
      return state;
  }
};

export const getQRCode = (state = false, action) => {
  switch (action.type) {
    case GET_QRCODE:
      return true;
    case GET_QRCODE_DONE:
      return false;
    default:
      return state;
  }
};
export const QRCode = (state = {}, action) => {
  switch (action.type) {
    case GET_QRCODE_DONE:
      return action.data;
    default:
      return state;
  }
};

export const markNotification = (state = false, action) => {
  switch (action.type) {
    case NOTIFICATION_REQUEST:
      return true;
    case NOTIFICATION_MARKED:
      return false;
    default:
      return state;
  }
};

export const notificationMarked = (state = false, action) => {
  switch (action.type) {
    case NOTIFICATION_MARKED:
      return action.data;
    case CLEAR:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  gettingRequestsList,
  requestsList,
  gettingRequestDetails,
  requestDetails,
  updattingRequestStatus,
  requestStatusUpdated,
  gettingRequestStatusChanges,
  requestStatusChanges,
  addingRequestNote,
  requestNoteAdded,
  getRequestNotes,
  requestNotes,
  sendingReply,
  replaySent,
  getRequestAttachments,
  requestAttachments,
  getRequestReplies,
  requestReplies,
  getQRCode,
  QRCode,
  markNotification,
  notificationMarked,
});
