import { createAction } from "../creators";

export default {
  ...createAction(
    "GET_ALL",
    "sort",
    "subSort",
    "subType",
    "tender",
    "last3Years",
    "acceptSponsors",
    "realToken",
    "categoryId",
    "eventId",
    "requestId",
    "hasAllocation",
    "language"
  ),
  ...createAction("ALL_RETURNED", "sort", "subSort", "data"),

  ...createAction(
    "GET_ALL_ITEMS",
    "sort",
    "subSort",
    "subType",
    "sponsorTypeId"
  ),
  ...createAction("GET_NOTIFICATIONS", "sort", "limit", "offset"),
  ...createAction("ALL_NOTIFICATIONS_RETURNED", "data"),
  ...createAction("CLEAR", "data"),
  ...createAction("ALL_ITEMS_RETURNED", "sort", "subSort", "data"),
  ...createAction("ALL_CLEAR", "data"),
  ...createAction("GET_BY_ID", "sort", "subSort", "id"),
  ...createAction("BY_ID_RETURNED", "sort", "subSort", "data"),
  ...createAction("GET_BY_ALIAS", "sort", "alias", "language"),
  ...createAction("BY_ALIAS_RETURNED", "sort", "subSort", "data"),

  ...createAction("CREATE_NEW", "sort", "subSort", "payload", "id", "language"),
  ...createAction("CREATED", "sort", "subSort", "response"),

  ...createAction("CALCULATE", "payload", "language"),
  ...createAction("CALCULATED", "response"),

  ...createAction("NEW_RESERVATION", "payload", "id"),
  ...createAction("GET_RESERVATIONS", "sort", "id"),

  ...createAction("CREATE_NEW_RESERVATION", "payload", "id", "language"),
  ...createAction("RESERVATION_CREATED", "response"),

  ...createAction("CREATE_NEW_SPACE_RESERVATION", "payload", "id", "language"),
  ...createAction("SPACE_RESERVATION_CREATED", "response"),

  ...createAction("ALL_RESERVATIONS_RETURNED", "sort", "data", "id"),
  ...createAction("GET_SUPPORTING", "sort", "subSort"),
  ...createAction("ALL_SUPPORTING_RETURNED", "sort", "subSort", "data"),
  ...createAction("CANCEL_ITEM", "sort", "id"),
  ...createAction("CANCELED", "sort", "response"),
  ...createAction("GET_ALL_AB", "sort", "subSort", "entity", "categoryId"),
  ...createAction("ALL_AB_RETURNED", "sort", "subSort", "postType", "data"),
  ...createAction("GET_UPDATE_DATE", "data"),

  ...createAction("GET_LAST_UPDATE", "sort"),
  ...createAction("LAST_UPDATE_RETURNED", "response"),
  ...createAction("PAYMENT", "payload"),
  ...createAction("PAYMENT_COMPLETED", "response"),

  ...createAction("GET_DATA_ALL", "data", "sort"),
  ...createAction("DATA_ALL_RETURNED", "data", "sort", "subSort"),

  ...createAction("GET_COMPACT", "data", "sort"),
  ...createAction("COMPACT_RETURNED", "data", "sort", "subSort"),
};
