import { createAction } from "../creators";

export default {
  ...createAction("GET_YEARS"),
  ...createAction("YEARS_RETURNED", "data"),

  ...createAction("FILTER_POSTS", "sort", "language", "data"),
  ...createAction("FILTER_POSTS_RETURNED", "data"),

  ...createAction("GET_CAT_YEARS", "sort", "subSort"),
  ...createAction("CAT_YEARS_RETURNED", "data"),

  ...createAction("GET_PUBLICATIONS_YEARS"),
  ...createAction("PUB_YEARS_RETURNED", "data"),

  ...createAction("GET_POST_YEARS", "sort", "field", "language"),
  ...createAction("POST_YEARS_RETURNED", "data"),
};
