import actions from "../../actions";
import { combineReducers } from "redux";

const {
  GET_YEARS,
  YEARS_RETURNED,
  FILTER_POSTS,
  FILTER_POSTS_RETURNED,
  GET_CAT_YEARS,
  CAT_YEARS_RETURNED,
  GET_PUBLICATIONS_YEARS,
  PUB_YEARS_RETURNED,
  GET_POST_YEARS,
  POST_YEARS_RETURNED,
} = actions;
export const getPublicationsYears = (state = false, action) => {
  switch (action.type) {
    case GET_PUBLICATIONS_YEARS:
      return true;
    case PUB_YEARS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const publicationsYearsReturned = (state = false, action) => {
  switch (action.type) {
    case PUB_YEARS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getCategoryYears = (state = false, action) => {
  switch (action.type) {
    case GET_CAT_YEARS:
      return true;
    case CAT_YEARS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const categoryYearsReturned = (state = false, action) => {
  switch (action.type) {
    case CAT_YEARS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const getEventsYears = (state = false, action) => {
  switch (action.type) {
    case GET_YEARS:
      return true;
    case YEARS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const eventsYearReturned = (state = false, action) => {
  switch (action.type) {
    case YEARS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export const requestYearPosts = (state = false, action) => {
  switch (action.type) {
    case FILTER_POSTS:
      return true;
    case FILTER_POSTS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const requestYearPostsReturned = (state = false, action) => {
  switch (action.type) {
    case FILTER_POSTS_RETURNED:
      return action.data;
    default:
      return state;
  }
};
export const getPostYears = (state = false, action) => {
  switch (action.type) {
    case GET_POST_YEARS:
      return true;
    case POST_YEARS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const postYearsReturned = (state = false, action) => {
  switch (action.type) {
    case POST_YEARS_RETURNED:
      return action.data;
    default:
      return state;
  }
};
export default combineReducers({
  getCategoryYears,
  categoryYearsReturned,
  getEventsYears,
  eventsYearReturned,
  getPublicationsYears,
  publicationsYearsReturned,
  requestYearPosts,
  requestYearPostsReturned,
  getPostYears,
  postYearsReturned,
});
