export const themeList = [
  {
    id: 1,
    name: "default Theme",
    isActive: true,
    createdAt: "2021-03-07T22:04:49.211Z",
    updatedAt: "2021-03-07T22:04:49.211Z",
    elements: {
      type: "light",
      direction: "ltr",
      fontFamily: { ar: "next", en: "poppins" },
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      fontSizeG: [28, 24, 20, 16, 12],
      colors: {
        white: "#fff",
        primary: "#1D4F91",
        textSecondary: "#888",
        secondary: "#D45D00",
        tertiary: "#143663",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#E59150",
      },
    },
  },
  {
    id: 2,
    name: "blue Theme",
    isActive: true,
    createdAt: "2021-03-07T22:04:49.211Z",
    updatedAt: "2021-03-07T22:04:49.211Z",
    elements: {
      type: "light",
      direction: "ltr",
      fontFamily: { ar: "next", en: "poppins" },
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      fontSizeG: [24, 20, 16],
      colors: {
        white: "#fff",
        primary: "#253F51",
        textSecondary: "#888",
        secondary: "#47799C",
        tertiary: "#E63C49",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#A6D9DC",
      },
    },
  },
  {
    id: 3,
    name: "red theme",
    isActive: true,
    createdAt: "2021-03-07T22:04:49.211Z",
    updatedAt: "2021-03-07T22:04:49.211Z",
    elements: {
      type: "light",
      direction: "ltr",
      fontFamily: { ar: "next", en: "poppins" },
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      fontSizeG: [24, 20, 16],
      colors: {
        white: "#fff",
        primary: "#E63C49",
        textSecondary: "#888",
        secondary: "#47799C",
        tertiary: "#253F51",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#A6D9DC",
      },
    },
  },
  {
    id: 3,
    name: "light blue theme",
    isActive: true,
    createdAt: "2021-03-07T22:04:49.211Z",
    updatedAt: "2021-03-07T22:04:49.211Z",
    elements: {
      type: "light",
      direction: "ltr",
      fontFamily: { ar: "next", en: "poppins" },
      fontSize: { xs: 12, s: 16, m: 20, lg: 24, xl: 28 },
      fontSizeG: [28, 24, 20, 16, 12],
      colors: {
        white: "#fff",
        primary: "#47799C",
        textSecondary: "#888",
        secondary: "#E63C49",
        tertiary: "#253F51",
        textDark: "#272727",
        textGeneral: "#22293D",
        lightBackground: "#E1E1E1",
        cards: "#A6D9DC",
      },
    },
  },
];
