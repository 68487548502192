import "./styles/App.css";
import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { store, history } from "./redux/store";
import Index from "./routes/index";
import { lazy } from "react";
import { Suspense } from "react";

const ScrollToTop = lazy(() =>
  import(
    /* webpackChunkName: "ScrollToTop" */ "./components/scrollToTop/ScrollToTop"
  )
);

function App() {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <Suspense fallback={<>Loading ...</>}>
          <ScrollToTop />
          <Index />
        </Suspense>
      </ConnectedRouter>
    </Provider>
  );
}

export default App;
