import { createAction } from "../creators";

export default {
  ...createAction("DO_SEARCH", "data", "language"),
  ...createAction("DO_SEARCH_RETURNED", "data"),

  ...createAction("SEARCH_MEDIA", "data", "language", "mediaType"),
  ...createAction("SEARCH_MEDIA_RETURNED", "data"),

  ...createAction("SEARCH", "sort", "data", "language"),
  ...createAction("SEARCH_RETURNED", "data"),
};
