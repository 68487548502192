import React, { memo } from "react";
import { Box } from "@material-ui/core";

function Spinner() {
  return (
    <Box
      style={{
        position: "fixed",
        display: "flex",
        top: 0,
        bottom: 0,
        zIndex: 60000,
        backdropFilter: "blur(2px)",
        right: 0,
        backgroundColor: "#ffffff1a",
        left: 0,
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <img
        src="/assets/spinner.gif"
        alt="spinner"
        style={{ maxHeight: "144px" }}
      />
    </Box>
  );
}

export default memo(Spinner);
