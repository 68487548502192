import actions from "../../actions";
import { combineReducers } from "redux";

const { SET_LANGUAGE } = actions;

const language = Boolean(JSON.parse(localStorage.getItem("isRTL")))
  ? "ar"
  : "en";

export const currentLanguage = (state = language, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return action.language;

    default:
      return state;
  }
};
export default combineReducers({
  currentLanguage,
});
