import { put, takeLatest, call } from "redux-saga/effects";
import {
  getAllCareers,
  getCareerById,
  submitCareerApp,
  getCareerByAlias,
} from "../../network/careers";
import actions from "../../actions";

const {
  GET_ALL_CAREERS,
  allCareersReturned,
  GET_CAREER_BY_ID,
  careerByIdReturned,
  POST_CAREER_APPLICATION,
  postCareerApplicationReturned,
  GET_CAREER_BY_ALIAS,
  getCareerByAliasReturned,
} = actions;

function* performGetAllCareers(action) {
  try {
    const { language } = action;
    const result = yield call(getAllCareers, language);

    if (result) yield put(allCareersReturned({ data: result.res.careers }));
    else yield put(allCareersReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllCareers() {
  yield takeLatest(GET_ALL_CAREERS, performGetAllCareers);
}

function* performGetCareerById(action) {
  try {
    const { id, language } = action;

    const result = yield call(getCareerById, id, language);

    if (result) yield put(careerByIdReturned({ data: result.res }));
    else yield put(careerByIdReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCareerById() {
  yield takeLatest(GET_CAREER_BY_ID, performGetCareerById);
}

function* performSubmitCv(action) {
  try {
    const data = action.data;

    const result = yield call(submitCareerApp, data);

    if (result) yield put(postCareerApplicationReturned({ data: result.data }));
    else yield put(postCareerApplicationReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchSubmitCv() {
  yield takeLatest(POST_CAREER_APPLICATION, performSubmitCv);
}

function* performGetCareerByAlias(action) {
  try {
    const { alias, language } = action;

    const result = yield call(getCareerByAlias, alias, language);

    if (result) yield put(getCareerByAliasReturned({ data: result.res }));
    else yield put(getCareerByAliasReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCareerByAlias() {
  yield takeLatest(GET_CAREER_BY_ALIAS, performGetCareerByAlias);
}
