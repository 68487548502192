import { createAction } from "../creators";
export default {
  ...createAction("GET_REQUESTS_LIST", "id"),
  ...createAction("GET_REQUESTS_LIST_DONE", "data"),
  ...createAction("GET_REQUEST_DETAILS", "id"),
  ...createAction("GET_REQUEST_DETAILS_DONE", "data"),
  ...createAction("UPDATE_REQUEST_STATUS", "data"),
  ...createAction("UPDATE_REQUEST_STATUS_DONE", "data"),
  ...createAction("UPDATE_REQUEST_STATUS_CLEAR"),
  ...createAction("GET_REQUEST_STATUS_CHANGES", "id"),
  ...createAction("GET_REQUEST_STATUS_CHANGES_DONE", "data"),
  ...createAction("ADD_REQUEST_NOTE", "data"),
  ...createAction("ADD_REQUEST_NOTE_DONE", "data"),
  ...createAction("GET_REQUEST_NOTES", "id"),
  ...createAction("GET_REQUEST_NOTES_DONE", "data"),
  ...createAction("SEND_REPLY", "data"),
  ...createAction("SEND_REPLY_DONE", "data"),
  ...createAction("GET_REQUEST_ATTACHMENTS", "id"),
  ...createAction("GET_REQUEST_ATTACHMENTS_DONE", "data"),
  ...createAction("GET_REQUEST_REPLIES", "id"),
  ...createAction("GET_REQUEST_REPLIES_DONE", "data"),
  ...createAction("GET_QRCODE", "data"),
  ...createAction("GET_QRCODE_DONE", "data"),
  ...createAction("MARK_NOTIFICATION", "data"),
  ...createAction("NOTIFICATION_MARKED", "data"),
};
