import React, { useState, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Spinner from "./Spinner";
import Routes from "./Routes";
import { setDirection } from "../redux/actionCreators/theme";
import { buildCanonicalUrl } from "../components/shared/utils";
import actions from "../redux/actions";
function Index() {
  const [processed, setProcessed] = useState(false);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { getMenu, getPageDetails, getAll, getLastUpdate } = actions;
  const isRTL = useSelector((state) => state.theme_reducer.basicTheme.isRTL);

  const currentLanguage = isRTL ? "ar" : "en";
  const { pathname: currentUrl } = useLocation();
  useEffect(() => {
    console.log("routing render");
    dispatch(getMenu());
    dispatch(getAll({ sort: "touch" }));
    dispatch(getAll({ sort: "configuration" }));
    dispatch(getLastUpdate({ sort: "lastUpdate" }));
  }, []);
  useEffect(() => {
    (async () => {
      setProcessed(false);
      let { canonicalUrl, canonicalLanguage } = await buildCanonicalUrl(
        currentUrl,
        currentLanguage
      );
      if (currentLanguage !== canonicalLanguage) {
        dispatch(setDirection({ isRTL: !isRTL }));
        i18n.changeLanguage(isRTL ? "en" : "ar");
      }

      if (canonicalUrl === currentUrl) {
        setProcessed(true);
        return;
      }

      window.location.replace(canonicalUrl);
    })();
  }, [currentUrl]);

  if (!processed) {
    return <Spinner />;
  }

  return (
    <Switch>
      <Route path="/:language/" component={Routes} />
    </Switch>
  );
}

export default Index;
