import actions from "../../actions";
import { combineReducers } from "redux";

const { GET_PAGE_DETAILS, PAGE_DETAILS_RETURNED } = actions;

export const getPageInfo = (state = false, action) => {
  switch (action.type) {
    case GET_PAGE_DETAILS:
      return true;
    case PAGE_DETAILS_RETURNED:
      return false;
    default:
      return state;
  }
};
export const pageInfoReturned = (state = false, action) => {
  switch (action.type) {
    case PAGE_DETAILS_RETURNED:
      return action.data;
    default:
      return state;
  }
};

export default combineReducers({
  getPageInfo,
  pageInfoReturned,
});
