import { fetchData } from "./api";

export const getYears = async () =>
  await fetchData("/api/filter/events/years", null, null);

export const getCatYears = async (type, subSort) =>
  await fetchData(`/api/filter/category/years`, { type, subSort }, null);

export const getPublicationsYears = async () =>
  await fetchData("/api/filter/publications/years", null, null);

export const getYearPosts = async (sort, data, language) =>
  await fetchData(`/api/filter/${sort}`, data, { language });

export const getPostYears = async (type, field, language) =>
  await fetchData(`/api/post/years`, { type, field }, { language });
