export const pagination = (displayedContent, itemsNum, pageNum) => {
  const count = Math.ceil(displayedContent?.length / itemsNum);
  const startIndex = (pageNum - 1) * itemsNum;
  const requiredArr = displayedContent.slice(startIndex, startIndex + itemsNum);
  return { count, requiredArr };
};
export const dynamicDataPagination = (itemsNum, pageNum) => {
  let startIndex = (pageNum - 1) * itemsNum;
  return { startIndex };
};

export const getImage = (files, isRTL) => {
  if (!files?.length) return;

  let enImg = "";
  let arImg = "";
  let defImg = "";
  files.map((file) => {
    if (!file) return null;
    const { publishMode, mimetype } = file;
    if (!mimetype?.includes("image")) return null;
    if (!defImg && isRTL && file?.id === 1) defImg = file;
    if (!defImg && !isRTL && file?.id === 2) defImg = file;
    if (!enImg && publishMode === 2 && file?.id != 2) enImg = file;
    if (!arImg && publishMode === 1 && file?.id != 1) arImg = file;

    return null;
  });
  const image = isRTL
    ? arImg
      ? arImg
      : enImg
      ? enImg
      : defImg
    : enImg
    ? enImg
    : arImg
    ? arImg
    : defImg;

  return {
    uuid: image?.uuid || "",
    alt: image?.alt ? (isRTL ? image?.alt?.ar : image?.alt?.en) : null,
  };
};

export const getImages = (files, isRTL) => {
  let images = [];
  if (!files?.length) return;

  let enImg = [];
  let arImg = [];
  let defImg = [];
  files.map((file) => {
    if (!file) return null;
    const { publishMode, mimetype } = file;
    if (!mimetype.includes("image")) return null;
    if (defImg && defImg?.length === 0 && isRTL && file?.id === 1)
      defImg?.push(file);
    if (defImg && defImg?.length === 0 && !isRTL && file?.id === 2)
      defImg?.push(file);
    if (publishMode === 2 && file?.id != 2) enImg?.push(file);
    if (publishMode === 1 && file?.id != 1) arImg?.push(file);
    return null;
  });
  images = isRTL
    ? arImg && arImg?.length > 0
      ? arImg
      : enImg && enImg?.length > 0
      ? enImg
      : defImg
    : enImg && enImg?.length > 0
    ? enImg
    : arImg && arImg?.length > 0
    ? arImg
    : defImg;
  return images;
};

export const getPagePicture = (parentMenu, pathname) => {
  const arr = [];

  parentMenu.childrens.map((menuItem) => {
    arr.push(menuItem);

    if (!menuItem.childrens.length) return null;

    menuItem.childrens.map((child) => {
      arr.push(child);
      return null;
    });
  });

  const item = arr.find((item) => item.link === pathname);

  if (!item) return "";
  const { pagePicture: { uuid = "" } = {} } = item;

  return uuid;
};

export const getFile = (files, isRTL) => {
  const file = files?.find((item) => {
    const { publishMode, mimetype } = item;
    const isFile = mimetype?.split("/")[0] === "application" ? true : false;

    if (item && !isRTL && publishMode === 2 && isFile) return true;
    else if (item && isRTL && publishMode === 1 && isFile) return true;
    else return false;
  });

  return {
    uuid: file?.uuid,
    alt: file?.title ? (isRTL ? file?.title.ar : file?.title.en) : null,
    readCount: file?.readCount,
    downloadCount: file?.downloadCount,
    fileId: file?.id,
  };
};

export const getFiles = (files, isRTL) => {
  let fileObjects = [];
  files.find((file) => {
    if (file) {
      const { publishMode, mimetype } = file;

      if (
        !isRTL &&
        publishMode === 2 &&
        mimetype.split("/")[0] === "application"
      )
        fileObjects = [
          ...fileObjects,
          {
            uuid: file.uuid || "",
            alt: file?.title?.en || file?.title?.ar || "",
          },
        ];
      else if (
        isRTL &&
        publishMode === 1 &&
        mimetype.split("/")[0] === "application"
      )
        fileObjects = [
          ...fileObjects,
          {
            uuid: file.uuid || "",
            alt: file?.title?.en || file?.title?.ar || "",
          },
        ];
    }
  });
  return fileObjects;
};

export const isFile = (file) => {
  const { mimetype } = file;
  return mimetype?.split("/")[0] === "application";
};

export const isImage = (image) => {
  const { mimetype } = image;
  return mimetype?.split("/")[0] === "image";
};

export const getFileUrl = async (fileName) => {
  return fetch(`/api/file/download/${fileName}`)
    .then((res) => res.blob())
    .then((res) => URL.createObjectURL(res));
};

export const staticPages = [
  {
    id: 1,
    link: "/search",
    parentPg: {
      title: { ar: "نتائج البحث", en: "Search Results" },
      link: "/search",
    },
  },
  {
    id: 2,
    link: "/services/login",
    parentPg: {
      title: { ar: "تسجيل الدخول", en: "Log in" },
      link: "/services/login",
    },
  },
  {
    id: 3,
    link: "/services/signup",
    parentPg: {
      title: { ar: "تسجيل مستخدم جديد", en: "Sign Up" },
      link: "/services/signup",
    },
  },
  {
    id: 4,
    link: "/services/forgetPassword",
    parentPg: {
      title: { ar: "إعادة تعيين كلمة المرور", en: "Reset Password" },
      link: "/services/forgetPassword",
    },
  },
  {
    id: 5,
    link: "/services/Confirmationcode",
    parentPg: {
      title: { ar: "كود التفعيل", en: "Confirmation Code" },
      link: "/services/Confirmationcode",
    },
  },
  {
    id: 6,
    link: "/services/dashboard",
    parentPg: {
      title: { ar: "لوحة التحكم", en: "Dashboard" },
      link: "/services/dashboard",
    },
  },
  {
    id: 7,
    link: "/services/dashboard/statistics",
    parentPg: {
      title: {
        ar: "لوحة التحكم",
        en: "Dashboard",
        link: "/services/dashboard/statistics",
      },
    },
    pg: {
      title: { ar: "الإحصائيات", en: "Statistics" },
      link: "/services/dashboard/statistics",
    },
  },
  {
    id: 8,
    link: "/services/dashboard/profile",
    parentPg: {
      title: { ar: "لوحة التحكم", en: "Dashboard" },
      link: "/services/dashboard/statistics",
    },
    pg: {
      title: { ar: "الملف الشخصي", en: "Profile" },
      link: "/services/dashboard/profile",
    },
  },
  {
    id: 9,
    link: "/services/dashboard/my_requests",
    parentPg: {
      title: { ar: "لوحة التحكم", en: "Dashboard" },
      link: "/services/dashboard/statistics",
    },
    pg: {
      title: { ar: "طلباتي", en: "My Requests" },
      link: "/services/dashboard/my_requests",
    },
  },
  {
    id: 10,
    link: "/services/dashboard/notifications",
    parentPg: {
      title: { ar: "لوحة التحكم", en: "Dashboard" },
      link: "/services/dashboard/statistics",
    },
    pg: {
      title: { ar: "الإشعارات", en: "Notifications" },
      link: "/services/dashboard/notifications",
    },
  },
  {
    id: 11,
    link: "/services/dashboard/RequestDetails",
    parentPg: {
      title: { ar: "لوحة التحكم", en: "Dashboard" },
      link: "/services/dashboard/statistics",
    },
    pg: {
      title: { ar: "تفاصيل الطلب", en: "Request Details" },
      link: "/services/dashboard/RequestDetails",
    },
  },
  {
    id: 11,
    link: "/services/thanks",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: {
      title: { ar: "صفحة الشكر", en: "Thanks Page" },
      link: "/services/thanks",
    },
  },
  {
    id: 12,
    link: "/services/venues/details",
    parentPg: {
      title: { ar: "القاعات", en: "Venues" },
      link: "/services/venues",
    },
    pg: { title: { ar: "التفاصيل", en: "Details" } },
  },
  {
    id: 13,
    link: "/services/Exhibition-Space-Booking",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "حجز مساحة", en: "Book Space" } },
  },
  {
    id: 14,
    link: "/services/Exhibition-Space-Booking/apply",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "حجز مساحة", en: "Book Space" } },
  },
  {
    id: 15,
    link: "/services/bookVenue/reservations",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "حجوزات القاعة", en: "Venue Reservations" } },
  },
  {
    id: 16,
    link: "/services/permitsTab",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "الرعاة", en: "Sponsors" } },
  },
  {
    id: 17,
    link: "/read/file",
    parentPg: { title: { ar: "الملف", en: "File" } },
    pg: { title: { ar: "قراءة", en: "Read" } },
  },
  {
    id: 18,
    link: "/services/sponsorform",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "كن راعيا", en: "Be a Sponsors" } },
  },
  {
    id: 19,
    link: "/services/eventsponsor/details",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "الرعاة", en: "Sponsors" } },
  },
  {
    id: 20,
    link: "/services/eventsponsor",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "الرعاة", en: "Sponsors" } },
  },
  {
    id: 21,
    link: "/services/serviceDetails",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "تفاصيل الخدمة", en: "Service Details" } },
  },
  {
    id: 22,
    link: "/services/permits",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "طلب الحصول على ترخيص", en: "Apply For Permit" } },
  },
  {
    id: 22,
    link: "/services/bookVenue",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: {
      title: { ar: "حجز قاعة", en: "Venues Booking" },
      link: "/services/venues",
    },
  },
  {
    id: 23,
    link: "/services/tenders/details",
    parentPg: {
      title: { ar: "الخدمات", en: "Services" },
      link: "/services/permitsTab",
    },
    pg: { title: { ar: "المناقصات", en: "Tenders" } },
  },
];

export const CountriesLTR = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe",
  "Palestine",
];

export const CountriesRTL = [
  "أفغانستان",
  "ألبانيا",
  "الجزائر",
  "ساموا الأمريكية",
  "أندورا",
  "أنغولا",
  "أنغيلا",
  "أنتاركتيكا",
  "أنتيغوا وبربودا",
  "الأرجنتين",
  "أرمينيا",
  "أروبا",
  "أستراليا",
  "النمسا",
  "أذربيجان",
  "جزر البهاما",
  "البحرين",
  "بنغلاديش",
  "بربادوس",
  "بيلاروسيا",
  "بلجيكا",
  "بليز",
  "بنن",
  "برمودا",
  "بوتان",
  "بوليفيا",
  "البوسنة والهرسك",
  "بوتسوانا",
  "تركيا",
  "جزيرة بوفيت",
  "البرازيل",
  "إقليم المحيط البريطاني الهندي",
  "بروناي دار السلام",
  "بلغاريا",
  "بوركينا فاسو",
  "بوروندي",
  "كمبوديا",
  "الكاميرون",
  "كندا",
  "الرأس الأخضر",
  "جزر كايمان",
  "جمهورية افريقيا الوسطى",
  "تشاد",
  "شيلي",
  "الصين",
  "جزيرة الكريسماس",
  "جزر كوكوس (كيلينغ)",
  "كولومبيا",
  "جزر القمر",
  "الكونغو",
  "جمهورية الكونغو الديمقراطية",
  "جزر كوك",
  "كوستا ريكا",
  "كوت ديفوار",
  "كرواتيا (هرفاتسكا)",
  "كوبا",
  "قبرص",
  "الجمهورية التشيكية",
  "الدنمارك",
  "جيبوتي",
  "دومينيكا",
  "جمهورية الدومينيكان",
  "تيمور الشرقية",
  "إكوادور",
  "مصر",
  "السلفادور",
  "غينيا الإستوائية",
  "إريتريا",
  "إستونيا",
  "أثيوبيا",
  "جزر فوكلاند (مالفيناس)",
  "جزر فاروس",
  "فيجي",
  "فنلندا",
  "فرنسا",
  "فرنسا متروبوليتان",
  "غيانا الفرنسية",
  "بولينيزيا الفرنسية",
  "المناطق الجنوبية لفرنسا",
  "غابون",
  "غامبيا",
  "جورجيا",
  "ألمانيا",
  "غانا",
  "جبل طارق",
  "اليونان",
  "الأرض الخضراء",
  "غرينادا",
  "جوادلوب",
  "غوام",
  "غواتيمالا",
  "غينيا",
  "غينيا - بيساو",
  "غيانا",
  "هايتي",
  "جزر هيرد وماكدونالد",
  "الكرسي الرسولي (دولة الفاتيكان)",
  "هندوراس",
  "هونغ كونغ",
  "هنغاريا",
  "أيسلندا",
  "الهند",
  "إندونيسيا",
  "إيران (جمهورية - الإسلامية)",
  "العراق",
  "أيرلندا",
  "إيطاليا",
  "جامايكا",
  "اليابان",
  "الأردن",
  "كازاخستان",
  "كينيا",
  "كيريباتي",
  "كوريا, الجمهورية الشعبية الديمقراطية",
  "جمهورية كوريا",
  "الكويت",
  "قيرغيزستان",
  "لاو , جمهورية الديمقراطية الشعبية",
  "لاتفيا",
  "لبنان",
  "ليسوتو",
  "ليبيريا",
  "الجماهيرية العربية الليبية",
  "ليختنشتاين",
  "ليتوانيا",
  "لوكسمبورغ",
  "ماكاو",
  "مقدونيا , جمهورية يوغوسلافيا السابقة",
  "مدغشقر",
  "ملاوي",
  "ماليزيا",
  "ملديف",
  "مالي",
  "مالطا",
  "جزر مارشال",
  "مارتينيك",
  "موريتانيا",
  "موريشيوس",
  "مايوت",
  "المكسيك",
  "ولايات ميكرونيزيا الموحدة",
  "جمهورية مولدوفا",
  "موناكو",
  "منغوليا",
  "مونتسيرات",
  "المغرب",
  "موزامبيق",
  "ميانمار",
  "ناميبيا",
  "ناورو",
  "نيبال",
  "هولندا",
  "جزر الأنتيل الهولندية",
  "كاليدونيا الجديدة",
  "نيوزيلاندا",
  "نيكاراغوا",
  "النيجر",
  "نيجيريا",
  "نيوي",
  "جزيرة نورفولك",
  "جزر مريانا الشمالية",
  "النرويج",
  "سلطنة عمان",
  "باكستان",
  "بالاو",
  "فلسطين",
  "بنما",
  "بابوا غينيا الجديدة",
  "باراغواي",
  "بيرو",
  "فيلبيني",
  "بيتكيرن",
  "بولندا",
  "البرتغال",
  "بورتوريكو",
  "دولة قطر",
  "جمع شمل",
  "رومانيا",
  "الاتحاد الروسي",
  "رواندا",
  "سانت كيتس ونيفيس",
  "القديسة لوسيا",
  "سانت فنسنت وجزر غرينادين",
  "ساموا",
  "سان مارينو",
  "سان تومي وبرينسيبي",
  "المملكة العربية السعودية",
  "السنغال",
  "سيشيل",
  "سيرا ليون",
  "سنغافورة",
  "سلوفاكيا (جمهورية سلوفاكيا)",
  "سلوفينيا",
  "جزر سليمان",
  "الصومال",
  "جنوب أفريقيا",
  "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
  "إسبانيا",
  "سيريلانكا",
  "سانت هيلانة",
  "سانت بيير وميكلون",
  "السودان",
  "سورينام",
  "جزر سفالبارد وجان ماين",
  "سوازيلند",
  "السويد",
  "سويسرا",
  "الجمهورية العربية السورية",
  "مقاطعة تايوان الصينية",
  "طاجيكستان",
  "جمهورية تنزانيا المتحدة",
  "تايلاند",
  "توجو",
  "توكيلاو",
  "تونغا",
  "ترينداد وتوباغو",
  "تونس",
  "تركمانستان",
  "جزر تركس وكايكوس",
  "توفالو",
  "أوغندا",
  "أوكرانيا",
  "الإمارات العربية المتحدة",
  "المملكة المتحدة",
  "الولايات المتحدة الأمريكية",
  "جزر الولايات المتحدة البعيدة الصغرى",
  "أوروغواي",
  "أوزبكستان",
  "فانواتو",
  "فنزويلا",
  "فيتنام",
  "جزر العذراء البريطانية)",
  "جزر فيرجن (الولايات المتحدة)",
  "جزر واليس وفوتونا",
  "الصحراء الغربية",
  "اليمن",
  "يوغوسلافيا",
  "زامبيا",
  "زمبابوي",
  "فلسطين",
];

export const Countries = [
  "Afghanistan",
  "Albania",
  "Algeria",
  "American Samoa",
  "Andorra",
  "Angola",
  "Anguilla",
  "Antarctica",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Aruba",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegowina",
  "Botswana",
  "Bouvet Island",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China",
  "Christmas Island",
  "Cocos (Keeling) Islands",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, the Democratic Republic of the",
  "Cook Islands",
  "Costa Rica",
  "Cote d'Ivoire",
  "Croatia (Hrvatska)",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands (Malvinas)",
  "Faroe Islands",
  "Fiji",
  "Finland",
  "France",
  "France Metropolitan",
  "French Guiana",
  "French Polynesia",
  "French Southern Territories",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Heard and Mc Donald Islands",
  "Holy See (Vatican City State)",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran (Islamic Republic of)",
  "Iraq",
  "Ireland",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic of",
  "Korea, Republic of",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macau",
  "Macedonia, The Former Yugoslav Republic of",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mayotte",
  "Mexico",
  "Micronesia, Federated States of",
  "Moldova, Republic of",
  "Monaco",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue",
  "Norfolk Island",
  "Northern Mariana Islands",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Palestine",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn",
  "Poland",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia (Slovak Republic)",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Georgia and the South Sandwich Islands",
  "Spain",
  "Sri Lanka",
  "St. Helena",
  "St. Pierre and Miquelon",
  "Sudan",
  "Suriname",
  "Svalbard and Jan Mayen Islands",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan, Province of China",
  "Tajikistan",
  "Tanzania, United Republic of",
  "Thailand",
  "Togo",
  "Tokelau",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks and Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States",
  "United States Minor Outlying Islands",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Virgin Islands (British)",
  "Virgin Islands (U.S.)",
  "Wallis and Futuna Islands",
  "Western Sahara",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe",
  "Palestine",
];

export const Emirates = [
  "Abu Dhabi",
  "Dubai",
  "Sharjah",
  "Ajman",
  "Umm Al Quwain",
  "Ras Al Khaimah",
  "Fujairah",
];

export const ArabicEmirates = [
  "أبو ظبي",
  "دبي",
  "الشارقة",
  "عجمان",
  "أم القيوين",
  "رأس الخيمة",
  "الفجيرة",
];
export function CountriesToArabic(country) {
  let index = CountriesLTR?.indexOf(country);

  let arabicCountry = CountriesRTL[index];

  return arabicCountry;
}
export function CountriesToEnglish(country) {
  let index = CountriesRTL?.indexOf(country);

  let englishCountry = CountriesLTR[index];

  return englishCountry;
}

export function EmirateToArabic(emirate) {
  let index = Emirates?.indexOf(emirate);
  let arabicEmirate = index > -1 ? ArabicEmirates[index] : emirate;
  return arabicEmirate;
}

function addLanguageToUrl(url, currentLanguage) {
  const urlParts = url?.split("/").filter(Boolean);

  if (urlParts[0] !== "en" && urlParts[0] !== "ar") {
    urlParts.unshift(currentLanguage);
  }
  return "/" + urlParts.join("/");
}

function resolveHome(url) {
  if (url === "/en") return "/en/home";
  if (url === "/ar") return "/ar/home";
  return url;
}

function extractLanguageFromUrl(url) {
  const regExpResilt = url.match(/^\/(ar|en)\/.*$/);
  if (!regExpResilt) return "ar";
  return regExpResilt[1];
}

export async function buildCanonicalUrl(currentUrl, currentLanguage) {
  sessionStorage.removeItem("language-switch");
  // let canonicalUrl = currentUrl.toLowerCase();
  let canonicalUrl = currentUrl;
  canonicalUrl = addLanguageToUrl(canonicalUrl, currentLanguage);
  canonicalUrl = resolveHome(canonicalUrl);
  const canonicalLanguage = extractLanguageFromUrl(canonicalUrl);
  return { canonicalUrl, canonicalLanguage };
}

export async function switchLanguages() {
  const url = window.location.pathname;
  const urlParts = url.split("/").filter(Boolean);

  const currentLanguage = urlParts[0];
  const targetLanguage = currentLanguage === "en" ? "ar" : "en";

  urlParts[0] = targetLanguage;

  sessionStorage.setItem("language-switch", true);

  window.location.href = "/" + urlParts.join("/");
}
