import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import {
  getAllPosts,
  getAllPosts2,
  getPostById,
  getPostByAlias,
  getSpaceByAlias,
  getAllPostsByCategory,
} from "../../network/post";
import actions from "../../actions";

const {
  GET_ALL_POSTS,
  allPostsReturned,
  GET_ALL_POSTS2,
  allPostsReturned2,
  GET_POST_BY_ID,
  postByIdReturned,
  GET_POST_BY_ALIAS,
  byAliasReturned,
  GET_SPACE_BY_ALIAS,
  spaceByAliasReturned,
  getUpdateDate,
  GET_POST_BY_CATEGORY,
  allPostsByCategoryReturned,
} = actions;

function* performGetAllPosts(action) {
  try {
    const { sort: data, isFeatured, language } = action;

    const result = yield call(getAllPosts, data, isFeatured, language);

    if (result) {
      yield put(allPostsReturned({ data: result.res.posts }));
      yield put(getUpdateDate({ data: result.res.lastUpdate }));
    } else yield put(allPostsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllPosts() {
  yield takeEvery(GET_ALL_POSTS, performGetAllPosts);
}

function* performGetAllPosts2(action) {
  try {
    const { data } = action;

    const result = yield call(getAllPosts2, data);

    if (result) {
      yield put(allPostsReturned2({ data: result.res.posts }));
      yield put(getUpdateDate({ data: result.res.lastUpdate }));
    } else yield put(allPostsReturned2({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllPosts2() {
  yield takeEvery(GET_ALL_POSTS2, performGetAllPosts2);
}

function* performGetPostById(action) {
  try {
    const { id, language } = action;
    const result = yield call(getPostById, id, language);
    if (result) {
      yield put(postByIdReturned({ data: result }));
      yield put(getUpdateDate({ data: result.res.updatedAt }));
    } else yield put(postByIdReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetPostById() {
  yield takeLatest(GET_POST_BY_ID, performGetPostById);
}

function* performGetPostByAlias(action) {
  try {
    const { alias, language } = action;
    const result = yield call(getPostByAlias, alias, language);
    if (result) {
      yield put(byAliasReturned({ data: result.res }));
      yield put(getUpdateDate({ data: result.res.updatedAt }));
    } else yield put(byAliasReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetPostByAlias() {
  yield takeLatest(GET_POST_BY_ALIAS, performGetPostByAlias);
}

function* performGetSpaceByAlias(action) {
  try {
    const { alias, language } = action;
    const result = yield call(getSpaceByAlias, alias, language);
    if (result) {
      yield put(spaceByAliasReturned({ data: result.res }));
    } else yield put(spaceByAliasReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetSpaceByAlias() {
  yield takeLatest(GET_SPACE_BY_ALIAS, performGetSpaceByAlias);
}

function* performGetAllPostsByCategory(action) {
  try {
    const { language, data } = action;
    const result = yield call(getAllPostsByCategory, data, language);
    if (result) yield put(allPostsByCategoryReturned({ data: result.res }));
    else yield put(allPostsByCategoryReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllPostsByCategory() {
  yield takeEvery(GET_POST_BY_CATEGORY, performGetAllPostsByCategory);
}
