import actions from "../../actions";
import { combineReducers } from "redux";

const { THANKS_ACTION, THANKS_RETURNED } = actions;

export const thanks = (state = false, action) => {
  switch (action.type) {
    case THANKS_ACTION:
      return { service: action.service, reqId: action.reqId };
    case THANKS_RETURNED:
      return false;
    default:
      return state;
  }
};

export const thanksActionReturned = (state = false, action) => {
  switch (action.type) {
    case THANKS_ACTION:
      return true;

    default:
      return state;
  }
};
export default combineReducers({
  thanks,
  thanksActionReturned,
});
