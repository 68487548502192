import { postData, fetchData } from "./api";
// let language = JSON.parse(localStorage.getItem("language"));
// if (!language) language = "en";

export const getAllPosts = async (type, isFeatured, language) =>
  await fetchData("/api/post", { type, isFeatured }, { language });

export const getAllPosts2 = async (data) =>
  await fetchData("/api/post", data.query, data.headers);

export const getPostById = async (id, language) =>
  await fetchData(`/api/post/${id}`, null, { language });

export const getPostByAlias = async (alias, language) =>
  await fetchData(`/api/post/${alias}`, null, { language });

export const getSpaceByAlias = async (alias, language) =>
  await fetchData(`/api/allocation/alias/${alias}`, null, { language });

export const getPostComments = async (id) =>
  await fetchData(`/api/post/${id}/comments`);

export const getAllPostsByCategory = async (data, language) =>
  await fetchData(
    "/api/post",
    {
      ...data,
    },
    { language }
  );
