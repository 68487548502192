import { fetchData, postData } from "./api";
let lang = localStorage.getItem("lang");

export const getCategories = async (
  type,
  isFeatured,
  last3Years,
  language,
  subType,
  limit,
  offset
) =>
  await fetchData(
    `/api/category`,
    { type, isFeatured, last3Years, subType, limit, offset },
    { language }
  );

export const getCategoryById = async (id, language) =>
  await fetchData(`/api/category/${id}`, null, { language });

export const getCategoryByAlias = async (alias, language) =>
  await fetchData(`/api/category/${alias}`, null, { language });

export const getCategoryTreeByAlias = async (alias) =>
  await fetchData(`/api/category/${alias}/tree`, null, null);

export const getCategoriesByYears = async (years, language) =>
  await fetchData(`/api/category/groups/years`, { years }, { language });
