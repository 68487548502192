import { fetchData } from "./api";

export const search = async (data, language) =>
  await fetchData("/api/search", { phrase: data }, { language });

export const searchVideos = async (data, language) =>
  await fetchData("/api/filter/video/gallery", data, { language });

export const searchPhotos = async (data, language) =>
  await fetchData("/api/filter/photo/gallery", data, { language });

export const searchPublications = async (data, language) =>
  await fetchData("/api/filter/publications", data, { language });

export const searchEvents = async (data, language) =>
  await fetchData("/api/filter/events", data, { language });

export const generalSearch = async (sort, data, language) =>
  await fetchData(`/api/${sort}`, {
    ...data,
    language,
  });
