import { createAction } from "../creators";

export default {
  ...createAction("GET_ALL_POSTS", "sort", "isFeatured", "language"), //sort = post type
  ...createAction("ALL_POSTS_RETURNED", "data"),
  ...createAction("GET_ALL_POSTS2", "data"),
  ...createAction("ALL_POSTS_RETURNED2", "data"),
  ...createAction("GET_POST_BY_ID", "id", "language"),
  ...createAction("POST_BY_ID_RETURNED", "id", "data"),
  ...createAction("GET_POST_BY_ALIAS", "alias", "language"),
  ...createAction("BY_ALIAS_RETURNED", "data"),
  ...createAction("GET_SPACE_BY_ALIAS", "alias", "language"),
  ...createAction("SPACE_BY_ALIAS_RETURNED", "data"),
  ...createAction("GET_POST_BY_CATEGORY", "data", "language"),
  ...createAction("ALL_POSTS_BY_CATEGORY_RETURNED", "data"),
};
