import { postData, fetchData } from "./api";
const localToken = localStorage.getItem("token");
const sessionToken = sessionStorage.getItem("rakExUserSession");

const token = sessionToken ? sessionToken : localToken;

export const fetchRequestsList = async () =>
  await fetchData(`/api/service/request`, null, { token });

export const fetchServiceRequestsList = async (id) =>
  await fetchData(`/api/service/${id}/requests`, null, { token });

export const fetchRequestDetails = async (id) =>
  await fetchData(`/api/service/request/${id}`, null, { token });

export const updateRequestStatus = async (data) =>
  await postData(`/api/service/request/${data.id}/status`, data.body, {
    token,
  });

export const fetchRequestStatusChanges = async (id) =>
  await fetchData(`/api/service/request/${id}/status`, null, { token });

export const addRequestNote = async (data) =>
  await postData(`/api/service/request/${data.id}/note`, data.body, {
    token,
  });

export const fetchRequestNotes = async (id) =>
  await fetchData(`/api/service/request/${id}/note`, null, { token });

export const createReply = async (data) =>
  await postData(`/api/replay/user`, data, {
    token,
  });

export const fetchRequestAttachements = async (id) =>
  await fetchData(`/api/service/request/${id}/attachments`, null, { token });

export const fetchRequestReplies = async (id) =>
  await fetchData(`/api/service/request/${id}/replays`, null, { token });

export const generateQRCode = async (data) =>
  await postData("/api/qrCode/generate", data, null);

export const markNotification = async (data) =>
  await postData(`/api/notification/mark/mine`, data, { token });
