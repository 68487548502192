import { call, put, takeEvery, select } from "redux-saga/effects";
import actions from "../../actions";
import {
  getAll,
  getAllItems,
  getData,
  getById,
  getByAlias,
  createNew,
  getReservations,
  cancelItem,
  update,
  getSupporting,
  getAllAb,
  calculate,
  createNewReservation,
  getLastUpdate,
  getNotifications,
  payment,
  createNewSpaceReservation,
  getCompact,
} from "../../network/crud";
import * as selectors from "../selectors";
import { createFile, uploadFile } from "../../network/file";
import { payload } from "../../utils/payload";
import { updateReqpayload } from "../../utils/updateBookingReqPayload";
const {
  GET_ALL,
  allReturned,
  GET_DATA_ALL,
  dataAllReturned,
  GET_ALL_ITEMS,
  allItemsReturned,
  GET_BY_ID,
  byIdReturned,
  GET_BY_ALIAS,
  byAliasReturned,
  CREATE_NEW,
  created,
  fileUploaded,
  GET_RESERVATIONS,
  allReservationsReturned,
  GET_SUPPORTING,
  allSupportingReturned,
  CANCEL_ITEM,
  canceled,
  GET_ALL_AB,
  allAbReturned,
  CALCULATE,
  calculated,
  CREATE_NEW_RESERVATION,
  reservationCreated,
  getUpdateDate,
  GET_LAST_UPDATE,
  lastUpdateReturned,
  GET_NOTIFICATIONS,
  allNotificationsReturned,
  PAYMENT,
  paymentCompleted,
  CREATE_NEW_SPACE_RESERVATION,
  spaceReservationCreated,
  GET_COMPACT,
  compactReturned,
} = actions;

function* performGetCompact(action) {
  try {
    let sort = action.sort;
    let subSort = action?.data?.type;
    let data = action.data;
    const result = yield call(getCompact, data, sort);
    if (result && result.res.success) {
      yield put(compactReturned({ data: result.res, sort, subSort }));
    } else {
      yield put(compactReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetCompact() {
  yield takeEvery(GET_COMPACT, performGetCompact);
}

function* performGetAll(action) {
  try {
    let sort = action.sort;
    let subSort = action.subSort;
    let subType = action.subType;
    let tender = action.tender;
    let last3Years = action.last3Years;
    let acceptSponsors = action.acceptSponsors;
    let sponsorTypeId = action.sponsorTypeId;
    let forYearly = action.forYearly;
    let categoryId = action.categoryId;
    let eventId = action?.eventId;
    let requestId = action?.requestId;
    let hasAllocation = action?.hasAllocation;
    const result = yield call(
      getAll,
      sort,
      subSort,
      subType,
      tender,
      last3Years,
      acceptSponsors,
      forYearly,
      categoryId,
      eventId,
      requestId,
      hasAllocation
    );
    if (result && result.res.success) {
      yield put(
        allReturned({
          data: result.res,
          sort,
          subSort,
          tender,
          last3Years,
          acceptSponsors,
          subType,
        })
      );
      yield put(getUpdateDate({ data: result.res.lastUpdate }));
    } else {
      yield put(allReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetAll() {
  yield takeEvery(GET_ALL, performGetAll);
}
function* performGetAllData(action) {
  try {
    let sort = action.sort;
    // let subSort = action.subSort;

    let data = action.data;
    const result = yield call(getData, data, sort);
    if (result && result.res.success) {
      yield put(dataAllReturned({ data: result.res, sort }));
    } else {
      yield put(dataAllReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllData() {
  yield takeEvery(GET_DATA_ALL, performGetAllData);
}

function* performGetAllItems(action) {
  try {
    let sort = action.sort;
    let subSort = action.subSort;
    let subType = action.subType;
    let sponsorTypeId = action.sponsorTypeId;
    const result = yield call(
      getAllItems,
      sort,
      subSort,
      subType,
      sponsorTypeId
    );

    if (result && result.res.success) {
      yield put(
        allItemsReturned({
          data: result.res,
          sort,
          subSort,
          sponsorTypeId,
          subType,
        })
      );
    } else {
      yield put(allItemsReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetAllItems() {
  yield takeEvery(GET_ALL_ITEMS, performGetAllItems);
}

function* performGetById(action) {
  try {
    let id = action.id;
    let sort = action.sort;
    const result = yield call(getById, sort, id);
    let files = [];
    let links = [];
    if (result)
      yield put(
        byIdReturned({
          data: { result: result.res, files: files, links: links },
        })
      );
    else yield put(byIdReturned({ data: [] }));
  } catch (error) {
    yield put(byIdReturned({ data: [] }));
  }
}

export function* watchGetById() {
  yield takeEvery(GET_BY_ID, performGetById);
}

function* performGetByAlias(action) {
  try {
    const { alias, sort, language } = action;

    const result = yield call(getByAlias, sort, alias, language);
    if (result) {
      yield put(byAliasReturned({ data: result.res }));
      yield put(getUpdateDate({ data: result.res.updatedAt }));
    } else yield put(byAliasReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}

export function* watchGetByAlias() {
  yield takeEvery(GET_BY_ALIAS, performGetByAlias);
}

function* performCreateNew(action) {
  try {
    let data = action.payload;
    let id = action.id;
    let exhibitionStudy = data.exhibitionStudy || [];
    let attach = data.attach;
    let exhibitionFloor = data.exhibitionFloor || [];
    let exactLocation = data.exactLocation || [];
    let noObjection = data.noObjection || [];
    let exhibitorsNames = data.exhibitorsNames || [];
    let economicPermit = data.economicPermit || [];
    let eventProposal = [];
    let replyFiles = data?.replyFiles || [];
    let exhibitionStudyId = [];
    let attachId = [];
    let exhibitionFloorId = [];
    let exactLocationId = [];
    let noObjectionId = [];
    let eventProposalId = [];
    let exhibitorsNamesId = [];
    let economicPermitId = [];
    let sort = action.sort;
    let filesIds = [];
    const currentDate = new Date();
    let direction = yield select(selectors.direction);
    if (attach && attach.length > 0) {
      var formData = new FormData();
      attach.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData.data.success) {
        let error = responseData.data.message;
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      attachId = allFilesPayload.data.files[0].id;

      data = { ...data, attachId: attachId };
    }
    if (exhibitionStudy && exhibitionStudy.length > 0) {
      var formData = new FormData();
      exhibitionStudy.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Exhibition Study_${index + 1}`,
            ar: `${index + 1}_دراسة المعرض`,
          },
          source: "client",
          extension: file.extension,
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      // allFilesPayload.data.files.map((files) => (exhibitionStudyId = files.id));
      allFilesPayload.data.files.map((files) =>
        exhibitionStudyId.push(files.id)
      );
      // exhibitionStudyId = allFilesPayload.data.files[0].id;

      data = { ...data, exhibitionStudyIds: exhibitionStudyId };
    }
    if (replyFiles && replyFiles.length) {
      var arFormData = new FormData();
      replyFiles.map((file) => {
        arFormData.append("files", file);
      });

      let responseData = yield call(uploadFile, arFormData);

      if (!responseData?.data?.success) {
        let error;
        if (responseData?.data?.code === 111)
          error = direction
            ? "يرجى تحميل ملف بالامتداد المسموح به فقط في تاب اللغة العربية"
            : "File Type is Not Allowed in Arabic Tab";
        else if (responseData?.data?.code === 112)
          error = direction
            ? "حجم الملفات كبير جدا"
            : "Files Size is Too Large";
        else error = responseData?.data?.message;

        yield put(fileUploaded({ response: error }));
      }
      let payload = [];
      responseData.data.files.map((file, index) => {
        payload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: {
            en: file.originalname,
            ar: file.originalname,
          },
          source: "client_relpy",
          ...file,
        });
      });

      let filesPayload = yield call(createFile, payload);
      filesPayload.data.files.map((files) => filesIds.push(files.id));
    }
    if (exhibitionFloor && exhibitionFloor.length > 0) {
      var formData = new FormData();
      exhibitionFloor.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Exhibition Floor_${index + 1}`,
            ar: `${index + 1}_أرضية المعرض`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) =>
        exhibitionFloorId.push(files.id)
      );
      // exhibitionFloorId = allFilesPayload.data.files[0].id;
      data = { ...data, exhibitionFloorIds: exhibitionFloorId };
    }
    if (exactLocation && exactLocation.length > 0) {
      var formData = new FormData();
      exactLocation.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Exact Location_${index + 1}`,
            ar: `${index + 1}_موقع المعرض الفعلي`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) => exactLocationId.push(files.id));
      // exactLocationId = allFilesPayload.data.files[0].id;
      data = { ...data, exactLocationIds: exactLocationId };
    }
    if (noObjection && noObjection.length > 0) {
      var formData = new FormData();
      noObjection.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `No Objection Letter_${index + 1}`,
            ar: `${index + 1}_خطاب لامانع من المالك`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) => noObjectionId.push(files.id));
      // noObjectionId = allFilesPayload.data.files[0].id;
      data = { ...data, noObjectionIds: noObjectionId };
    }
    if (eventProposal && eventProposal.length > 0) {
      var formData = new FormData();
      eventProposal.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Event Proposal_${index + 1}`,
            ar: `${index + 1}_عرض مبدأي للفعالية`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) => eventProposalId.push(files.id));
      // eventProposalId = allFilesPayload.data.files[0].id;
      data = { ...data, eventProposalIds: eventProposalId };
    }
    if (exhibitorsNames && exhibitorsNames.length > 0) {
      var formData = new FormData();
      exhibitorsNames.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Exhibitors Names_${index + 1}`,
            ar: `${index + 1}_اسم الجهة العارضة`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      allFilesPayload.data.files.map((files) =>
        exhibitorsNamesId.push(files.id)
      );
      // allFilesPayload.data.files.map((files) => (exhibitorsNamesId = files.id));
      // exhibitorsNamesId = allFilesPayload.data.files[0].id;

      data = { ...data, exhibitorsNamesIds: exhibitorsNamesId };
    }
    if (economicPermit && economicPermit.length > 0) {
      var formData = new FormData();
      economicPermit.map((file) => {
        formData.append("files", file);
      });

      let responseData = yield call(uploadFile, formData);
      if (!responseData?.data?.success) {
        let error = "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filesPayload = [];
      responseData.data.files.map((file, index) => {
        filesPayload.push({
          uuid: file.filename || null,
          title: {
            en: `Economic Department Permit_${index + 1}`,
            ar: `${index + 1}_تصريح الإدارة الاقتصادية`,
          },
          extension: file.extension,
          source: "client",
          ...file,
        });
      });
      let allFilesPayload = yield call(createFile, filesPayload);
      // allFilesPayload.data.files.map((files) => (economicPermitId = files.id));
      allFilesPayload.data.files.map((files) =>
        economicPermitId.push(files.id)
      );
      // economicPermitId = allFilesPayload.data.files[0].id;

      data = { ...data, economicPermitIds: economicPermitId };
    }
    if (sort === "service/request/besponsor") {
      eventProposal = data.eventProposal;
      if (eventProposal && eventProposal.length > 0) {
        var formData = new FormData();
        eventProposal.map((file) => {
          formData.append("files", file);
        });

        let responseData = yield call(uploadFile, formData);
        if (!responseData?.data?.success) {
          let error = "Files Size is Too Large";
          yield put(fileUploaded({ response: error }));
        }
        let filesPayload = [];
        responseData.data.files.map((file, index) => {
          filesPayload.push({
            uuid: file.filename || null,
            title: {
              en: "Event Proposal",
              ar: "عرض مبدأي للفعالية",
            },
            extension: file.extension,
            source: "client",
            ...file,
          });
        });
        let allFilesPayload = yield call(createFile, filesPayload);

        eventProposalId = allFilesPayload.data.files[0].id;
        data = {
          ...data,
          eventProposalIds: eventProposalId,
        };
      }
    }
    let creatingData = [];

    if (sort === "service/request/booking" && id) {
      creatingData = yield updateReqpayload(data, sort, filesIds, null);
    } else {
      creatingData = yield payload(data, sort, filesIds, null);
    }

    let result = [];
    if (id) {
      result = yield call(update, sort, creatingData, id);
    } else {
      result = yield call(createNew, sort, creatingData);
    }
    if (result?.data?.success) {
      yield put(created({ response: result.data }));
    } else {
      yield put(created({ response: result.data }));
    }
  } catch (e) {
    yield put(created({ response: "" }));
    return;
  }
}

export function* watchCreateNew() {
  yield takeEvery(CREATE_NEW, performCreateNew);
}

function* performCalculate(action) {
  try {
    let data = action.payload;
    let payload = {
      checkInDate: data?.checkInDate,
      checkOutDate: data?.checkOutDate,
      startDate: data?.startDate,
      endDate: data?.endDate,
      eventCategoryId: data?.exhibitionCategory,
      roomIds: data?.roomIds,
      facilitiesIds: data?.facilites,
    };
    const result = yield call(calculate, payload);
    if (result && result?.data?.success) {
      yield put(calculated({ response: result?.data?.cost }));
    } else {
      yield put(calculated({ response: [] }));
    }
  } catch (error) {
    console.error(error);
    yield put(calculated({ response: [] }));
  }
}

export function* watchCalculate() {
  yield takeEvery(CALCULATE, performCalculate);
}

function* performGetLastUpdate(action) {
  try {
    let sort = action.sort;
    const result = yield call(getLastUpdate, sort);
    if (result && result?.networkSuccess) {
      yield put(lastUpdateReturned({ response: result?.res?.lastUpdate }));
    } else {
      yield put(lastUpdateReturned({ response: [] }));
    }
  } catch (error) {
    console.error(error);
    yield put(lastUpdateReturned({ response: [] }));
  }
}

export function* watchGetLastUpdate() {
  yield takeEvery(GET_LAST_UPDATE, performGetLastUpdate);
}

function* performCreateNewReservation(action) {
  try {
    let data = action.payload;
    let allReservations = data?.newReservations;
    let exhibitionStudyIds = [];
    let exhibitionFloorIds = [];
    let exhibitorsNamesIds = [];
    let economicPermitIds = [];
    if (allReservations && allReservations?.length > 0) {
      for (let i = 0; i < allReservations.length; i++) {
        if (
          allReservations[i]?.exhibitionStudy &&
          allReservations[i]?.exhibitionStudy.length > 0
        ) {
          var formData = new FormData();
          allReservations[i]?.exhibitionStudy.map((file) => {
            formData.append("files", file);
          });
          let responseData = yield call(uploadFile, formData);
          if (!responseData?.data?.success) {
            let error = "Files Size is Too Large";
            yield put(fileUploaded({ response: error }));
          }
          let filesPayload = [];
          responseData.data.files.map((file, index) => {
            filesPayload.push({
              uuid: file.filename || null,
              title: {
                en: `Exhibition Study_${index + 1}`,
                ar: `${index + 1}_دراسة المعرض`,
              },
              source: "client",
              extension: file.extension,
              ...file,
            });
          });

          let allFilesPayload = yield call(createFile, filesPayload);
          // allFilesPayload.data.files.map((files) => (exhibitionStudyId = files.id));
          allFilesPayload.data.files.map((files) =>
            exhibitionStudyIds.push(files.id)
          );
          // exhibitionStudyId = allFilesPayload.data.files[0].id;

          allReservations[i] = {
            ...allReservations[i],
            exhibitionStudyIds: exhibitionStudyIds,
          };
        }

        if (
          allReservations[i]?.exhibitionFloor &&
          allReservations[i]?.exhibitionFloor.length > 0
        ) {
          var formData = new FormData();
          allReservations[i]?.exhibitionFloor.map((file) => {
            formData.append("files", file);
          });

          let responseData = yield call(uploadFile, formData);
          if (!responseData?.data?.success) {
            let error = "Files Size is Too Large";
            yield put(fileUploaded({ response: error }));
          }
          let filesPayload = [];
          responseData.data.files.map((file, index) => {
            filesPayload.push({
              uuid: file.filename || null,
              title: {
                en: `Exhibition Floor_${index + 1}`,
                ar: `${index + 1}_أرضية المعرض`,
              },
              source: "client",
              extension: file.extension,
              ...file,
            });
          });
          let allFilesPayload = yield call(createFile, filesPayload);
          allFilesPayload.data.files.map((files) =>
            exhibitionFloorIds.push(files.id)
          );
          // exhibitionFloorId = allFilesPayload.data.files[0].id;
          allReservations[i] = {
            ...allReservations[i],
            exhibitionFloorIds: exhibitionFloorIds,
          };
        }

        if (
          allReservations[i]?.exhibitorsNames &&
          allReservations[i]?.exhibitorsNames.length > 0
        ) {
          var formData = new FormData();
          allReservations[i]?.exhibitorsNames.map((file) => {
            formData.append("files", file);
          });

          let responseData = yield call(uploadFile, formData);
          if (!responseData?.data?.success) {
            let error = "Files Size is Too Large";
            yield put(fileUploaded({ response: error }));
          }
          let filesPayload = [];
          responseData.data.files.map((file, index) => {
            filesPayload.push({
              uuid: file.filename || null,
              title: {
                en: `Exhibitors Names_${index + 1}`,
                ar: `${index + 1}_اسم الجهة العارضة`,
              },
              source: "client",
              extension: file.extension,
              ...file,
            });
          });
          let allFilesPayload = yield call(createFile, filesPayload);
          allFilesPayload.data.files.map((files) =>
            exhibitorsNamesIds.push(files.id)
          );
          // allFilesPayload.data.files.map((files) => (exhibitorsNamesId = files.id));
          // exhibitorsNamesId = allFilesPayload.data.files[0].id;

          allReservations[i] = {
            ...allReservations[i],
            exhibitorsNamesIds: exhibitorsNamesIds,
          };
        }

        if (
          allReservations[i]?.economicPermit &&
          allReservations[i]?.economicPermit.length > 0
        ) {
          var formData = new FormData();
          allReservations[i]?.economicPermit.map((file) => {
            formData.append("files", file);
          });

          let responseData = yield call(uploadFile, formData);
          if (!responseData?.data?.success) {
            let error = "Files Size is Too Large";
            yield put(fileUploaded({ response: error }));
          }
          let filesPayload = [];
          responseData.data.files.map((file, index) => {
            filesPayload.push({
              uuid: file.filename || null,
              title: {
                en: `Economic Department Permit_${index + 1}`,
                ar: `${index + 1}_تصريح الإدارة الاقتصادية`,
              },
              source: "client",
              extension: file.extension,
              ...file,
            });
          });
          let allFilesPayload = yield call(createFile, filesPayload);
          // allFilesPayload.data.files.map((files) => (economicPermitId = files.id));
          allFilesPayload.data.files.map((files) =>
            economicPermitIds.push(files.id)
          );
          // economicPermitId = allFilesPayload.data.files[0].id;

          allReservations[i] = {
            ...allReservations[i],
            economicPermitIds: economicPermitIds,
          };
        }
      }
    }

    let payload = {
      reservations: allReservations,
      responseTypes: data.responseTypes,
      message: data.message,
    };
    const result = yield call(createNewReservation, payload);

    if (result && result?.data?.success) {
      yield put(reservationCreated({ response: result }));
    } else {
      yield put(reservationCreated({ response: [] }));
    }
  } catch (error) {
    console.error(error);
    yield put(reservationCreated({ response: [] }));
  }
}

export function* watchCreateNewReservation() {
  yield takeEvery(CREATE_NEW_RESERVATION, performCreateNewReservation);
}

function* performCreateNewSpaceReservation(action) {
  try {
    let data = action.payload;
    let reservedSections = data?.reservedSections;
    let responseTypes = [];
    if (data?.phoneData) responseTypes?.push("phone");
    if (data?.emailData) responseTypes?.push("email");
    let payload = {
      regionIds: reservedSections?.map((item) => item?.id),
      responseTypes: responseTypes,
      message: data.message,
      initialCost: data?.cost,
    };
    const result = yield call(createNewSpaceReservation, payload);

    if (result && result?.data?.success) {
      yield put(spaceReservationCreated({ response: result }));
    } else {
      yield put(spaceReservationCreated({ response: [] }));
    }
  } catch (error) {
    console.error(error);
    yield put(spaceReservationCreated({ response: [] }));
  }
}

export function* watchCreateNewSpaceReservation() {
  yield takeEvery(
    CREATE_NEW_SPACE_RESERVATION,
    performCreateNewSpaceReservation
  );
}

function* performGetReservations(action) {
  try {
    let sort = action.sort;
    let id = action.id;
    const result = yield call(getReservations, sort, id);
    if (result && result.res.success) {
      yield put(allReservationsReturned({ data: result.res, sort, id }));
    } else {
      yield put(allReservationsReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
    yield put(allReservationsReturned({ data: [] }));
  }
}

export function* watchGetAllReservations() {
  yield takeEvery(GET_RESERVATIONS, performGetReservations);
}
function* performCancel(action) {
  try {
    let id = action.id;
    let sort = action.sort;
    const result = yield call(cancelItem, sort, id);
    if (result.data.success) {
      yield put(canceled({ response: result.data.success }));
    } else {
      yield put(canceled({ response: result.data.success }));
    }
  } catch (error) {
    yield put(canceled({ response: "" }));
  }
}
export function* watchCancel(action) {
  yield takeEvery(CANCEL_ITEM, performCancel);
}

function* performGetSupporting(action) {
  try {
    let sort = action.sort;
    let subSort = action.subSort;
    const result = yield call(getSupporting, sort, subSort);
    if (result && result.res.success) {
      yield put(
        allSupportingReturned({
          data: result.res,
          sort,
          subSort,
        })
      );
    } else {
      yield put(allSupportingReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetSupporting() {
  yield takeEvery(GET_SUPPORTING, performGetSupporting);
}

function* performGetAllAb(action) {
  try {
    let sort = action.sort;
    let subSort = action.subSort;
    let entity = action.entity;
    let categoryId = action.categoryId;
    const result = yield call(getAllAb, sort, subSort, entity, categoryId);
    if (result && result.res.success) {
      yield put(allAbReturned({ data: result.res, sort, subSort }));
      yield put(getUpdateDate({ data: result.res.lastUpdate }));
    } else {
      yield put(allAbReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetAllAb() {
  yield takeEvery(GET_ALL_AB, performGetAllAb);
}
function* performGetNotifications(action) {
  try {
    let sort = action.sort;
    let limit = action.limit;
    let offset = action.offset;
    const result = yield call(getNotifications, sort, limit, offset);
    if (result && result.res.success) {
      yield put(allNotificationsReturned({ data: result.res }));
    } else {
      yield put(allNotificationsReturned({ data: [] }));
    }
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetNotifications() {
  yield takeEvery(GET_NOTIFICATIONS, performGetNotifications);
}
function* performPayment(action) {
  try {
    let data = action.payload;
    let invoice = data?.payInvoice || [];
    let direction = yield select(selectors.direction);
    let filesIds = [];
    if (invoice && invoice.length) {
      var arFormData = new FormData();
      invoice.map((file) => {
        arFormData.append("files", file);
      });
      let responseData = yield call(uploadFile, arFormData);
      if (!responseData.data.success) {
        let error = direction
          ? "حجم الملفات كبير جدا"
          : "Files Size is Too Large";
        yield put(fileUploaded({ response: error }));
      }
      let filePayload = [];
      responseData.data.files.map((file, index) => {
        filePayload.push({
          uuid: file.filename || null,
          extension: file.extension,
          title: { ar: "الدفعة", en: "Batch" },
          source: "Batch",
          ...file,
        });
      });
      let filesPayload = yield call(createFile, filePayload);
      filesPayload.data.files.map((files) => filesIds.push(files.id));
    }
    data = { ...data, attachmentId: filesIds[0] };
    let creatingData = {
      attachmentId: data?.attachmentId,
      batchId: data?.batchId,
    };
    let result = yield call(payment, creatingData);
    if (result)
      yield put(
        paymentCompleted({
          response: result.data,
        })
      );
    else yield put(paymentCompleted({ data: [] }));
  } catch (error) {
    console.error(error, "error");
  }
}
export function* watchPayment() {
  yield takeEvery(PAYMENT, performPayment);
}
