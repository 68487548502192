import { put, takeLatest, call } from "redux-saga/effects";
import { contactUs } from "../../network/contactus";
import actions from "../../actions";

const { CONTACT_US_ACTION, contactUsReturned } = actions;

function* performContactUs(action) {
  try {
    let data = action.data;

    const result = yield call(contactUs, data);

    if (result) yield put(contactUsReturned({ res: result }));
    else yield put(contactUsReturned({ res: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetContactusSubjects() {
  yield takeLatest(CONTACT_US_ACTION, performContactUs);
}
