import { put, takeLatest, call } from "redux-saga/effects";
import {
  getYears,
  getYearPosts,
  getCatYears,
  getPublicationsYears,
  getPostYears,
} from "../../network/filter";
import actions from "../../actions";

const {
  GET_CAT_YEARS,
  catYearsReturned,
  GET_YEARS,
  yearsReturned,
  FILTER_POSTS,
  filterPostsReturned,
  GET_PUBLICATIONS_YEARS,
  pubYearsReturned,
  GET_POST_YEARS,
  postYearsReturned,
} = actions;

function* performGetPublicationsYears(action) {
  try {
    const result = yield call(getPublicationsYears);

    if (result) yield put(pubYearsReturned({ data: result.res }));
    else yield put(pubYearsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetPublicationsYears() {
  yield takeLatest(GET_PUBLICATIONS_YEARS, performGetPublicationsYears);
}

function* performGetCatYears(action) {
  try {
    const { sort: type, subSort } = action;

    const result = yield call(getCatYears, type, subSort);

    if (result) yield put(catYearsReturned({ data: result.res }));
    else yield put(catYearsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetCatYears() {
  yield takeLatest(GET_CAT_YEARS, performGetCatYears);
}

function* performGetYears(action) {
  try {
    const result = yield call(getYears);

    if (result) yield put(yearsReturned({ data: result.res }));
    else yield put(yearsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetYears() {
  yield takeLatest(GET_YEARS, performGetYears);
}

function* performGetYearEvents(action) {
  try {
    const { sort, data, language } = action;
    const result = yield call(getYearPosts, sort, data, language);
    if (result) yield put(filterPostsReturned({ data: result.res }));
    else yield put(filterPostsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetYearEvents() {
  yield takeLatest(FILTER_POSTS, performGetYearEvents);
}

function* performGetPostYears(action) {
  try {
    let type = action?.sort;
    let field = action?.field;
    let language = action?.language;
    const result = yield call(getPostYears, type, field, language);
    if (result) yield put(postYearsReturned({ data: result.res }));
    else yield put(postYearsReturned({ data: [] }));
  } catch (error) {
    console.error(error);
  }
}
export function* watchGetPostYears() {
  yield takeLatest(GET_POST_YEARS, performGetPostYears);
}
